import { Alert, Button, Card } from 'flowbite-react';
import AdvaForm from '@src/components/form/adva-form';
import { useTranslation } from 'react-i18next';
import PasswordStrengthIndicator from '@src/components/form/password-strength-indicator';
import { useState } from 'react';
import { FormErrorState, UserResetPasswordProps } from '@src/types/form/adva-form-props';
import SetFormErrorInitialState from '@src/helpers/set-form-error-initial-state';
import AdvaPasswordInput from '@src/components/form/inputs/adva-password-input';
import handleInputChange from '@src/helpers/handle-input-change';
import ComponentLoading from '@src/components/loading-screen/component-loading';
import validateForm from '@src/helpers/validate-form';
import {  UserChangePasswordSchema } from '@src/schemas/access/forgot-password-schema';
import Api from '@src/services/request-service';

const UserPasswordChange = () => {
    const { t } = useTranslation( 'resetpassword' );

    const [ formData, setFormData ] = useState<UserResetPasswordProps>( { oldPassword: '', password: '', repeatPassword: '' } );
    const [ errors, setErrors ] = useState<FormErrorState>( SetFormErrorInitialState( formData ) );
    const [ serverMessage, setServerMessage ] = useState( { type: '', message: '' } );
    const [ sending, setSending ] = useState( false );

    const handleChange = ( event: any ) => {
        handleInputChange(
            event,
            formData,
            setFormData,
            errors,
            setErrors
        );
    };

    const handleSubmit = ( event: any ) => {
        event.preventDefault();
        setSending( true );
        setErrors( SetFormErrorInitialState( formData ) );

        const isValid = validateForm<UserResetPasswordProps>( UserChangePasswordSchema, formData, setErrors );

        if ( !isValid ) {
            setSending( false );
            return;
        }

        Api.patch( 'auth/changePassword', { password: formData.password, repeatPassword: formData.repeatPassword, oldPassword: formData.oldPassword } )
            .then( ( response ) => {
                setServerMessage( { type: 'success', message: response.data.message } );
                setSending( false );
                setFormData( {
                    ...formData,
                    password: '',
                    repeatPassword: '',
                    oldPassword: ''
                } );
            } )
            .catch( ( error ) => {
                setServerMessage( { type: 'failure', message: error.response.data.errorMessage } );
                setSending( false );
            } );
    };

    return (
        <Card className="relative">
            <h3 className="mb-4 text-xl font-bold dark:text-white">
                {t( 'resetpassword:pageTitle' )}
            </h3>
            <AdvaForm onSubmit={( e )=> handleSubmit( e )}>
                <AdvaPasswordInput
                    className="mb-6 flex flex-col gap-y-3"
                    validation={errors['oldPassword']}
                    value={formData.oldPassword}
                    name="oldPassword"
                    id="oldPassword"
                    label={t( 'resetpassword:current_password' )}
                    onChange={( e: any ) => handleChange( e )}
                />

                <AdvaPasswordInput
                    className="mb-6 flex flex-col gap-y-3"
                    name="password"
                    id="password"
                    validation={errors['password']}
                    value={formData.password}
                    label={t( 'resetpassword:new_password' )}
                    onChange={( e: any ) => handleChange( e )}
                />

                <AdvaPasswordInput
                    className="mb-6 flex flex-col gap-y-3"
                    name="repeatPassword"
                    id="repeatPassword"
                    validation={errors['repeatPassword']}
                    value={formData.repeatPassword}
                    label={t( 'resetpassword:confirm_password' )}
                    onChange={( e: any ) => handleChange( e )}
                />

                <div  className="mb-6 gap-y-3">
                    <Button disabled={sending} type="submit" className="w-full lg:w-auto">
                        {t( 'common:save' )}
                    </Button>
                </div>
                {
                    serverMessage.message.length > 0 ?
                        <Alert
                            color={serverMessage.type}
                            onDismiss={() => setServerMessage( { message: '', type: '' } )}
                        >
                            {serverMessage.message}
                        </Alert>
                        : ''
                }
            </AdvaForm>
            <hr/>
            <PasswordStrengthIndicator password={formData.password} />

            {sending ?
                <ComponentLoading /> :
                '' }
        </Card>
    );
};

export default UserPasswordChange;