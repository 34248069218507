import { AdvaFormProps } from '@src/types/form/adva-form-props';

const AdvaForm = ( { onSubmit, children }: AdvaFormProps ) => {
    return (
        // eslint-disable-next-line no-empty-function
        <form onSubmit={onSubmit ? onSubmit : ()=> { }}>
            {children}
        </form>
    );
};

export default AdvaForm;