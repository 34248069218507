import { useRef, useState } from 'react';
import styles from '@src/components/drag-and-drop-fIle/dragdrop.module.css';
import { Button } from 'flowbite-react';
import { useTranslation } from 'react-i18next';

export const DragAndDropFile = ( { handleFileSelect }: { handleFileSelect: Function } ) => {
    const { t } = useTranslation();
    // drag state
    const [ dragActive, setDragActive ] = useState( false );
    const inputRef = useRef<any>( null );

    // handle drag events
    const handleDrag = function ( e: any ) {
        e.preventDefault();
        e.stopPropagation();
        if ( e.type === 'dragenter' || e.type === 'dragover' ) {
            setDragActive( true );
        } else if ( e.type === 'dragleave' ) {
            setDragActive( false );
        }
    };

    // triggers when file is dropped
    const handleDrop = function ( e: any ) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive( false );
        if ( e.dataTransfer.files && e.dataTransfer.files[0] ) {
            handleFileSelect( e.dataTransfer.files[0] );
        }
    };

    // triggers when file is selected with click
    const handleChange = function ( e: any ) {
        e.preventDefault();
        if ( e.target.files && e.target.files[0] ) {
            handleFileSelect( e.target.files[0] );
        }
    };

// triggers the input when the button is clicked
    const onButtonClick = () => {
        inputRef.current!.click();
    };

    return (
        <form id={styles['form-file-upload']} onDragEnter={handleDrag} onSubmit={( e ) => e.preventDefault()}>
            <input ref={inputRef} id="input-file-upload" type="file" className={styles['input-file-upload']}
                   onChange={handleChange}/>
            <label id={styles['label-file-upload']} htmlFor="input-file-upload"
                   className={dragActive ? [ styles['drag-active'], styles['label-file-upload'] ].join( ' ' ) : styles['label-file-upload']}>
                <div>
                    <p className="dark:text-white">{t( 'common:drag_drop_desc' )}</p>
                    <Button
                        className=" border-1 border-blue-600 text-white uppercase rounded-none mx-auto mt-4"
                        onClick={onButtonClick}>{t( 'common:upload_file' )}</Button>
                </div>
            </label>
            {dragActive &&
                <div id={styles['drag-file-element']} onDragEnter={handleDrag} onDragLeave={handleDrag}
                     onDragOver={handleDrag}
                     onDrop={handleDrop}/>}
        </form>
    );
};