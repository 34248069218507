import { OrganisationBadgeProps } from '@src/types/organisation-badge/organisation-badge-props';
import Avatar from '@src/components/avatar/avatar';
import { useAppSelector } from '@src/hooks/redux-hooks';
import { selectIsOpenOnSmallScreens } from '@src/redux/slices/sidebar-slice';
import classNames from 'classnames';

const OrganisationBadge = ( { organisation, branchName, avatarSize=8 }: OrganisationBadgeProps ) => {

    const orgName = organisation && organisation.name ? organisation.name : 'Organisation Name';
    const orgLogo = organisation && organisation.logo ? organisation.logo : '';
    const orgNavigate  = organisation && organisation.id ? `/organisation/${organisation.id}`: '';
    const isSidebarOpenOnSmallScreens = useAppSelector( selectIsOpenOnSmallScreens );

    return(
        <div className={classNames( 'flex mt-[-10px] justify-center flex-col items-center', {
            'p-5': !isSidebarOpenOnSmallScreens,
            'mt-[-10px]': !isSidebarOpenOnSmallScreens,
            'mt-[10px]': isSidebarOpenOnSmallScreens,
        } )}>
            <Avatar
                navigate={orgNavigate}
                size={avatarSize}
                alt={orgName}
                img={orgLogo}
            />

            {!isSidebarOpenOnSmallScreens ?
                <>
                    <h2 className="mt-5 dark:text-white">{orgName || ''}</h2>
                    <small className="dark:text-white">{branchName || ''}</small>
                </>
                : ''
            }

        </div>
    );
};

export default OrganisationBadge;