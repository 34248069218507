import { ReactNode } from 'react';
// import { useNavigate } from 'react-router-dom';
import { useAuth } from '@src/context/auth-context';
import NotFoundComponent from '@src/app/not-found/not-found';
import currentUserCan from '@src/helpers/current-user-can';
 const ViewGuard = ( { children, permission, additionalCheck }: { children: ReactNode, permission: string, additionalCheck?: Function } ) => {
    const { currentUser } = useAuth();
    const permissions = currentUser.userProfile.role!.permissions;
    let havePermission =  currentUserCan( permissions, permission );

    if( typeof additionalCheck === 'function' ) {
        if ( havePermission || additionalCheck() ) {
            return <>{children}</>;
        }
    }

    if ( !havePermission ) {
        // @ts-ignore
        return <NotFoundComponent/>;
    }

    return <>{children}</>;
};

 export default ViewGuard;