import AppLayout from '@src/layouts/app-layout';
import TranslationNamspace from '@src/enums/translation-namespace';
import { useTranslation } from 'react-i18next';

const Dashboard = () => {
    const { t } = useTranslation( [ TranslationNamspace.COMMON ] );

    return(
            <AppLayout>
                <p style={{ marginTop: '100px', paddingLeft: '100px' }}>{t( 'common:page_not_found' )}</p>
            </AppLayout>
    );
};

export default Dashboard;