export enum Permissions {
    CAN_ADD_ORGANISATIONS = 'can_add_organisations',
    CAN_EDIT_ORGANISATIONS = 'can_edit_organisations',
    CAN_READ_ORGANISATIONS = 'can_read_organisations',
    CAN_DELETE_ORGANISATIONS = 'can_delete_organisations',
    CAN_EDIT_CURRENT_ORGANISATION = 'can_edit_current_organisation',
    CAN_READ_CURRENT_ORGANISATION = 'can_read_current_organisation',
    CAN_ADD_BRANCHES = 'can_add_branches',
    CAN_EDIT_BRANCHES = 'can_edit_branches',
    CAN_READ_BRANCHES = 'can_read_branches',
    CAN_DELETE_BRANCHES = 'can_delete_branches',
    CAN_ADD_DEPARTMENTS = 'can_add_departments',
    CAN_EDIT_DEPARTMENTS = 'can_edit_departments',
    CAN_READ_DEPARTMENTS = 'can_read_departments',
    CAN_DELETE_DEPARTMENTS = 'can_delete_departments',
    CAN_ADD_ROLES = 'can_add_roles',
    CAN_EDIT_ROLES = 'can_edit_roles',
    CAN_READ_ROLES = 'can_read_roles',
    CAN_DELETE_ROLES = 'can_delete_roles',
    CAN_ADD_TEAMS = 'can_add_teams',
    CAN_EDIT_TEAMS = 'can_edit_teams',
    CAN_READ_TEAMS = 'can_read_teams',
    CAN_DELETE_TEAMS = 'can_delete_teams',
    CAN_ADD_EMPLOYEES = 'can_add_employees',
    CAN_EDIT_EMPLOYEES = 'can_edit_employees',
    CAN_READ_EMPLOYEES = 'can_read_employees',
    CAN_DELETE_EMPLOYEES = 'can_delete_employees',
    CAN_VIEW_DEPARTMENTS_IN_BRANCH = 'can_view_departments_in_branch',
    CAN_VIEW_ROLES_IN_BRANCH = 'can_view_roles_in_branch',
    CAN_VIEW_EMPLOYEES_IN_BRANCH = 'can_view_employees_in_branch',
    CAN_EDIT_ASSIGNMENT_BOARD_IN_BRANCH = 'can_edit_assignment_board_in_branch',
    CAN_VIEW_ASSIGNMENT_BOARD_IN_BRANCH = 'can_view_assignment_board_in_branch',
    CAN_RUN_MIGRATIONS = 'can_run_migrations',
    CAN_RUN_SEED = 'can_run_seed',
    CAN_READ_API_DOCS = 'can_read_api_docs',
    CAN_ADD_SUPER_ADMIN = 'can_add_super_admin',
    CAN_READ_SUPER_ADMIN = 'can_read_super_admin',
    CAN_EDIT_SUPER_ADMIN = 'can_edit_super_admin',
    CAN_DELETE_SUPER_ADMIN = 'can_delete_super_admin',
}