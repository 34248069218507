import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from '@src/app/dashboard/Dashboard';
import Providers from '@src/providers/providers';
import NotFoundComponent from '@src/app/not-found/not-found';
import LogInComponent from '@src/app/access/login/log-in-component';
import ForgotPasswordComponent from '@src/app/access/forgot-password/forgot-password-component';
import ResetPasswordComponent from '@src/app/access/reset-password/reset-password-component';
import ProfileSettingsComponent from '@src/app/profile-settings/profile-settings-component';
import initTranslations from '@src/i18n';
import './App.css';
import Cookies from 'js-cookie';
import TranslationNamespace from '@src/enums/translation-namespace';
import AddOrganisationComponent from '@src/app/organisations/add/add-organisation-component';
import EditOrganisationComponent from '@src/app/organisations/edit/edit-organisation-component';
import OrganisationProfileComponent from '@src/app/organisations/profile/organisation-profile-component';
import OrganisationsList from '@src/app/organisations/list/organisations-list';

let resizeTimeout: any;

window.addEventListener( 'resize', function() {
    clearTimeout( resizeTimeout );
    resizeTimeout = setTimeout( function() {
        // Handle resize event here
    }, 250 );
} );

const  App = () => {
  const locale = Cookies.get( 'locale' ) || 'de';
  initTranslations( locale, [ Object.values( TranslationNamespace ) ] );

    return (
      <Router>
          <Providers>
              <Routes>
                  <Route path="/"  element={<Dashboard />} />
                  <Route path="/access" element={<LogInComponent/>} />
                  <Route path="/access/forgot-password" element={<ForgotPasswordComponent/>} />
                  <Route path="/access/forgot-password/:verificationToken" element={<ResetPasswordComponent/>} />
                  <Route path="/profile-settings"  element={<ProfileSettingsComponent />} />
                  <Route path="/organisation/list" element={<OrganisationsList/>} />
                  <Route path="/organisation/add" element={<AddOrganisationComponent/>} />
                  <Route path="/organisation/edit/:orgId" element={<EditOrganisationComponent/>} />
                  <Route path="/organisation/:orgId" element={<OrganisationProfileComponent/>} />
                  <Route path="*" element={<NotFoundComponent />} />
              </Routes>
          </Providers>
      </Router>
  );
};

export default App;
