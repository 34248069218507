import { Dropdown } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';


type Flags = {
    [key: string] : ReactNode
}

const LanguageDropdown = () => {
    const { i18n } = useTranslation();
    const currentLocale = i18n.language;

    const flags: Flags = {
        en: <div style={{ width: '24px', height: '24px', borderRadius: '50%', backgroundPosition: 'center', backgroundSize: '35px', backgroundRepeat: 'no-repeat', backgroundImage: 'url(\'/images/flags/gb.svg\')' }}> </div>,
        de: <div style={{ width: '24px', height: '24px', borderRadius: '50%', backgroundPosition: 'center', backgroundSize: '35px', backgroundRepeat: 'no-repeat', backgroundImage: 'url(\'/images/flags/de.svg\')' }}> </div>,
    };

    const handleChange = async ( locale: string ) => {

        const newLocale = locale;

        // set cookie for next-i18n-router
        const days = 30;
        const date = new Date();
        date.setTime( date.getTime() + days * 24 * 60 * 60 * 1000 );
        const expires = date.toUTCString();
        document.cookie = `locale=${newLocale};expires=${expires};path=/`;

        await i18n.changeLanguage( newLocale );
    };

    return (
        <Dropdown
            arrowIcon={false}
            inline
            dismissOnClick={true}
            label={
                <span className="current_lang inline-flex cursor-pointer justify-center rounded p-2 text-gray-500 hover:bg-gray-100 hover:text-gray-900 dark:hover:bg-gray-700 dark:hover:text-white">
                    { flags[currentLocale as keyof Flags] }
                </span>
            }
        >
            <Dropdown.Item
                onClick={()=>handleChange( 'de' )}
                className="cursor-pointer block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
            >
                <div className="inline-flex items-center">
                    {flags['de']}
                    <span className="ml-3 whitespace-nowrap">Deutsch</span>
                </div>
            </Dropdown.Item>
            <Dropdown.Item
                onClick={()=>handleChange( 'en' )}
                className="cursor-pointer block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
            >
                <div className="inline-flex items-center">
                    {flags['en']}
                    <span className="ml-4 whitespace-nowrap">English</span>
                </div>
            </Dropdown.Item>
        </Dropdown>
    );
};

export default LanguageDropdown;