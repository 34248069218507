import { Alert, Button, Card } from 'flowbite-react';
import AdvaForm from '@src/components/form/adva-form';
import AdvaTextInput from '@src/components/form/inputs/adva-text-input';
import { useState } from 'react';
import { ForgotPasswordProps, FormErrorState } from '@src/types/form/adva-form-props';
import SetFormErrorInitialState from '@src/helpers/set-form-error-initial-state';
import handleInputChange from '@src/helpers/handle-input-change';
import validateForm from '@src/helpers/validate-form';
import { ForgotPasswordSchema } from '@src/schemas/access/forgot-password-schema';
import Api from '@src/services/request-service';
import LanguageDropdown from '@src/components/sidebar/language-dropdown';
import ComponentLoading from '@src/components/loading-screen/component-loading';
import TranslationNamespace from '@src/enums/translation-namespace';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ForgotPasswordComponent = () => {
    const [ formData, setFormData ] = useState<ForgotPasswordProps>( { email: '' } );
    const [ errors, setErrors ] = useState<FormErrorState>( SetFormErrorInitialState( formData ) );
    const [ serverMessage, setServerMessage ] = useState( { type: '', message: '' } );
    const [ sending, setSending ] = useState( false );
    const navigate = useNavigate();
    const { t } = useTranslation( [ TranslationNamespace.FORGOT_PASSWORD, TranslationNamespace.COMMON, TranslationNamespace.VALIDATION_ERRORS ] );
    const handleChange = ( event: any ) => {
        handleInputChange(
            event,
            formData,
            setFormData,
            errors,
            setErrors
        );
    };

    const handleSubmit = async ( event: any ) => {
        event.preventDefault();
        setSending( true );
        setErrors( SetFormErrorInitialState( formData ) );

        const isValid = validateForm<ForgotPasswordProps>( ForgotPasswordSchema, formData, setErrors );

        if ( !isValid ) {
            setSending( false );
            return;
        }

        Api.post( '/auth/forgotPassword', { email: formData.email } )
            .then( ( response ) => {
                setServerMessage( { type: 'success', message: response.data.message } );
                setSending( false );
                setFormData( {
                    ...formData,
                    email: ''
                } );
            } )
            .catch( ( error ) => {
                setServerMessage( { type: 'failure', message: error.response.data.errorMessage } );
                setSending( false );
            } );

    };

    return (
        <HelmetProvider>
            <Helmet>
                <title>{t( 'pageTitle' )} | Advacare</title>
            </Helmet>
            <div className="flex flex-col items-center justify-center px-6 lg:h-screen lg:gap-y-12">
                <a href="/" className="my-6 flex items-center gap-x-1 lg:my-0">
                    <img
                        alt="Flowbite logo"
                        src="/images/advacare2.svg"
                        className="mr-3 h-10"
                    />
                </a>
                <Card className="w-full lg:max-w-[640px] lg:[&>*]:w-full lg:[&>*]:p-16 relative">
                    <h1 className="text-2xl font-bold dark:text-white md:text-3xl">
                        {t( 'title' )}
                    </h1>
                    <p className="mb-3 text-gray-500 dark:text-gray-300">
                        {t( 'paragraph' )}
                    </p>

                    <AdvaForm
                        onSubmit={( e: any ) => handleSubmit( e )}
                    >
                        <AdvaTextInput
                            validation={errors['email']}
                            value={formData.email}
                            className="mb-6 flex flex-col gap-y-3"
                            name="email"
                            id="email"
                            placeholder="name@company.com"
                            label={t( 'common:email' )}
                            type="text"
                            onChange={( e: any ) => handleChange( e )}
                        />
                        <div>
                            <Button disabled={sending} type="submit" className="w-full lg:w-auto">
                                {t( 'common:reset_password_btn' )}
                            </Button>
                        </div>
                    </AdvaForm>

                    <div className="text-right">
                        <a
                            onClick={()=>{navigate( '/access' );}}
                            className="cursor-pointer text-right text-sm  text-primary-600 dark:text-primary-300"
                        >
                            {t( 'common:back_to_login_btn' )}
                        </a>
                    </div>

                    {
                        serverMessage.message.length > 0 ?
                            <Alert
                                color={serverMessage.type}
                                onDismiss={() => setServerMessage( { message: '', type: '' } )}
                            >
                                {serverMessage.message}
                            </Alert>
                            : ''
                    }
                    {sending ?
                        <ComponentLoading /> :
                        '' }
                </Card>
                <div className="flex justify-center items-center">
                    <p className="mr-3">{t( 'common:language' )} </p>
                    <LanguageDropdown />
                </div>
            </div>
        </HelmetProvider>
    );
};

export default ForgotPasswordComponent;