import { Alert, Card } from 'flowbite-react';
import { HiCloudUpload } from 'react-icons/hi';
import ComponentLoading from '@src/components/loading-screen/component-loading';
import { ProfileImageCrop } from '@src/components/crop-profile-image/crop-profile-image';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Constants } from '@src/enums/constants';

interface AvatarCardProps {
    title: string;
    image: string;
    subTitle?: string;
    loading?: boolean;
    onChangeImage?: any;
    responseData?: any;
    setResponseData?: any;
    onSave: any
    onError: any
}

const AvatarCard = ( { title, image, subTitle, loading, onChangeImage, responseData, setResponseData, onSave, onError }: AvatarCardProps ) => {
    const { t } = useTranslation();
    const [ cardImage, setCardImg ] = useState( image || Constants.DEFAULT_USER_IMAGE );
    const [ openModal, setOpenModal ] = useState( false );

    useEffect( () => {
        setCardImg( image );
    }, [ image ] );

    return(
        <>
            <Card className="relative">
                <div className="items-center sm:flex sm:space-x-4 xl:block xl:space-x-0 2xl:flex 2xl:space-x-4">
                    <img
                        alt={title}
                        src={cardImage}
                        className="mb-4 h-28 w-28 rounded-lg sm:mb-0 xl:mb-4 2xl:mb-0"
                    />
                    <div>
                        <h3 className="mb-1 text-2xl font-bold text-gray-900 dark:text-white">
                            {title}
                        </h3>
                        {
                            subTitle ?
                                <div className="text-base font-normal text-gray-500 dark:text-gray-400">
                                    {subTitle}
                                </div>
                                : ''
                        }
                        <a
                            onClick={onChangeImage || ( () => setOpenModal( true ) )}
                            className="cursor-pointer inline-flex mt-4 items-center rounded-lg bg-primary-400 px-3 py-2 text-center text-sm font-medium text-white hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-400 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                        >
                            <HiCloudUpload className="mr-2" />
                            {t( 'common:change_profile_img' )}
                        </a>
                    </div>
                </div>
                {
                    responseData && responseData.message.length > 0 ?
                        <Alert
                            color={responseData.type}
                            onDismiss={() => setResponseData( { message: '', type: '' } )}
                        >
                            {responseData.message}
                        </Alert>
                        : ''
                }
                {loading ?
                    <ComponentLoading /> :
                    '' }
            </Card>

            <ProfileImageCrop
                openCropper={openModal}
                setOpenCropper={setOpenModal}
                onSave={onSave}
                setImage={setCardImg}
                onError={onError}
            />
        </>
    );
};

export default AvatarCard;