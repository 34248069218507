import { FormErrorState } from '@src/types/form/adva-form-props';
import { Dispatch, SetStateAction } from 'react';

const handleInputChange = <T>(
    event: any,
    formData: T,
    setFormData: Dispatch<SetStateAction<T>>,
    errors: FormErrorState,
    setErrors: Dispatch<SetStateAction<FormErrorState>>
) => {
    const data = { ...formData };
    const input = event.target;
    const name = input.name;
    data[name as keyof T] = input.value;
    setFormData( data );

    // @ts-ignore
    if( errors[name] && !errors[name].valid ) {
        setErrors( {
            ...errors,
            [name]: {
                valid: true,
                errorMessageKey: ''
            }
        } );
    }
};

export default handleInputChange;