import 'react-advanced-cropper/dist/style.css';
import { useRef, useState } from 'react';
import { DragAndDropFile } from '@src/components/drag-and-drop-fIle/drag-and-drop-file';
import { arrayBufferToBase64 } from '@src/helpers/array-buffer-to-base64';
import { CustomCropper } from '@src/components/crop-profile-image/custom-cropper';
import { FixedCropperRef } from 'react-advanced-cropper';
import { Button, Modal } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { Constants } from '@src/enums/constants';

interface IProfileImgCrop {
    openCropper: boolean
    setOpenCropper: Function
    onSave: Function
    onError: Function
    setImage?: Function
}

export const ProfileImageCrop = ( { onSave, openCropper, setOpenCropper, onError, setImage }: IProfileImgCrop ) => {
    const cropperRef = useRef<FixedCropperRef>( null );
    const { t } = useTranslation();
    const [ croppedImage, setCroppedImage ] = useState( null as string | ArrayBuffer | null );
    
    const imgArea = !croppedImage ?
        <DragAndDropFile handleFileSelect={( file: any ) => {
            const reader = new FileReader();
            reader.onload = function () {
                const arrayBuffer = new Uint8Array( reader.result as ArrayBuffer );
                const img = arrayBufferToBase64( arrayBuffer );
                setCroppedImage( `data:image/jpeg;base64,${img}` );
            };
            reader.readAsArrayBuffer( file );
        }}/> :
        <>
            <CustomCropper
                ref={cropperRef}
                stencilSize={{ width: 500, height: 500 }}
                src={croppedImage as string}
            />
        </>;


    const handleSaveImage = async () => {
        try {
            setOpenCropper( false );

            const editedImage = cropperRef.current?.getCanvas( {
                height: 250,
                width: 250,
            } )?.toDataURL();

            onSave( editedImage as string || Constants.DEFAULT_USER_IMAGE );

            if ( typeof setImage === 'function' ) {
                const newImage = editedImage && editedImage.length > 0 ? editedImage : Constants.DEFAULT_USER_IMAGE;
                setImage( newImage );
            }

        } catch ( error: any ) {
            onError( error );
        }
    };

    return (
        <>
            <Modal show={openCropper} onClose={() => setOpenCropper( false )}>
                <Modal.Header>{t( 'common:upload_image' )}</Modal.Header>
                <Modal.Body>
                    <div className="space-y-6">
                        {imgArea}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => handleSaveImage()}>{t( 'common:save' )}</Button>
                    <Button color="gray" onClick={() => setCroppedImage( null )}>
                        {t( 'common:remove_image' )}
                    </Button>
                </Modal.Footer>

            </Modal>
        </>

    );
};