import { Button } from 'flowbite-react';
import type { FC } from 'react';
import { HiChevronLeft } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import TranslationNamespace from '@src/enums/translation-namespace';

const ServerErrorPage: FC = function () {
    const { t } = useTranslation( [ TranslationNamespace.ERROR, TranslationNamespace.COMMON ] );
    return (
        <HelmetProvider>
            <Helmet>
                <title>{t( 'error:pageTitle' )} | Advacare</title>
            </Helmet>
        <div className="flex min-h-screen flex-col items-center justify-center py-16">
            <img alt="" src="/images/illustrations/500.svg" className="lg:max-w-md" />
            <h1 className="mb-3 w-4/5 text-center text-2xl font-bold dark:text-white md:text-5xl">
                {t( 'error:something_went_wrong' )}
            </h1>
            <p className="mb-6 w-4/5 text-center text-lg text-gray-500 dark:text-gray-300">
                {t( 'error:error_description' )}
            </p>
            <Button href="/">
                <div className="mr-1 flex items-center gap-x-2">
                    <HiChevronLeft className="text-xl" /> {t( 'common:back' )}
                </div>
            </Button>
        </div>
        </HelmetProvider>
    );
};

export default ServerErrorPage;
