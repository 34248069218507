import type { CustomFlowbiteTheme } from 'flowbite-react';

const flowbiteTheme: CustomFlowbiteTheme = {
  badge: {
    root: {
      color: {
        info: 'bg-primary-100 text-primary-800 dark:bg-primary-200 dark:text-primary-800 group-hover:bg-primary-200 dark:group-hover:bg-primary-300',
        primary:
          'bg-primary-100 text-primary-800 dark:bg-primary-200 dark:text-primary-800 group-hover:bg-primary-200 dark:group-hover:bg-primary-300',
      },
      size: {
        xl: 'px-3 py-2 text-base rounded-md',
      },
    },
    icon: {
      off: 'rounded-full px-2 py-1',
    },
  },
  button: {
    color: {
      gray: 'text-gray-900 bg-white border border-gray-200 enabled:hover:bg-gray-100 enabled:hover:text-primary-700 :ring-primary-700 focus:text-primary-700 dark:bg-transparent dark:text-gray-400 dark:border-gray-600 dark:enabled:hover:text-white dark:enabled:hover:bg-gray-700 focus:ring-2',
      info: 'text-white bg-primary-400 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-400 dark:hover:bg-primary-800 dark:focus:ring-primary-800',
      primary:
        'text-white bg-primary-400 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-400 dark:hover:bg-primary-800 dark:focus:ring-primary-800',
    },
    inner: {
      base: 'flex items-center transition-all duration-200',
    },
    outline: {
      color: {
        gray: 'border border-gray-200 dark:border-gray-500',
      },
    },
  },
  dropdown: {
    floating: {
      base: 'z-10 w-fit rounded-xl divide-y divide-gray-100 shadow',
      content: 'rounded-xl text-sm text-gray-700 dark:text-gray-200',
      target: 'w-fit dark:text-white',
    },
    content: '',
  },
  modal: {
    content: {
      inner: 'relative rounded-lg bg-white shadow dark:bg-gray-800',
    },
    header: {
      base: 'flex items-start justify-between rounded-t px-5 pt-5',
    },
  },
  navbar: {
    root: {
      base: 'fixed z-30 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700',
    },
  },
  sidebar: {
    root: {
      base: 'bg-white dark:bg-gray-800 flex fixed top-0 left-0 z-20 flex-col flex-shrink-0 pt-16 h-full duration-75 border-r border-gray-200 lg:flex transition-width dark:border-gray-700',
      inner: 'h-full overflow-y-auto overflow-x-hidden rounded bg-white  dark:bg-gray-800'
    },
    items: {
      base: 'px-3'
    },
    item: {
      //base: 'flex bg-primary-400 items-center justify-center rounded-lg p-2 text-base font-300 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700',
    },
    collapse: {
      button:
        'group flex w-full items-center rounded-lg p-2 text-base font-300 text-gray-900 transition duration-75 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700',

    },
  },
  textarea: {
    base: 'block w-full text-sm p-4 rounded-lg border disabled:cursor-not-allowed disabled:opacity-50',
  },
  textInput: {
    field: {
      input: {
        base: 'block w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-cyan-500 focus:ring-cyan-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-cyan-500 dark:focus:ring-cyan-500 p-2.5 text-sm',
        colors: {
          info: 'border-primary-500 bg-primary-50 text-primary-900 placeholder-primary-700 focus:border-primary-500 focus:ring-primary-500 dark:border-primary-400 dark:bg-primary-100 dark:focus:border-primary-500 dark:focus:ring-primary-500',
        },
        withIcon: {
          // on: '!pl-12',
        },
      },
    },
  },
  toggleSwitch: {
    toggle: {
      checked: {
        color: {
          blue: 'bg-primary-700 border-primary-700',
        },
      },
    },
  },
};

export default flowbiteTheme;
