import { Footer } from 'flowbite-react';
import { useTranslation } from 'react-i18next';

const MainFooter = () => {
    const { t } = useTranslation();
    return (
        <>
            <Footer container>
                <div className="flex w-full flex-col gap-y-6 lg:flex-row lg:justify-between lg:gap-y-0">
                    <Footer.LinkGroup>
                        <Footer.Link href="#" className="mr-3 mb-3 lg:mb-0">
                            {t( 'common:terms_and_conditions' )}
                        </Footer.Link>
                        <Footer.Link href="#" className="mr-3 mb-3 lg:mb-0">
                            {t( 'common:cookie_policy' )}
                        </Footer.Link>
                        <Footer.Link href="#" className="mr-3">
                            {t( 'common:privacy_policy' )}
                        </Footer.Link>
                        <Footer.Link href="#">
                            {t( 'common:contact' )}
                        </Footer.Link>
                    </Footer.LinkGroup>
                </div>
            </Footer>
            <p className="my-8 text-center text-sm text-gray-500 dark:text-gray-300">
                &copy; {new Date().getFullYear()} Advacare.  {t( 'common:copyright' )}
            </p>
        </>
    );
};

export default MainFooter;