import AppLayout from '@src/layouts/app-layout';
import { Permissions } from '@src/enums/Permissions';
import currentUserCan from '@src/helpers/current-user-can';
import { useAuth } from '@src/context/auth-context';
import ViewGuard from '@src/components/view-guard/view-guard';
import { useParams } from 'react-router';
import { Breadcrumb } from 'flowbite-react';
import { HiHome } from 'react-icons/hi';
import { useGetOrganisationByIdQuery } from '@src/services/organisations-service';
import LoadingScreen from '@src/components/loading-screen/loading-screen';
import ServerErrorPage from '@src/app/error/error';
import { useTranslation } from 'react-i18next';
import ProfileIntro from '@src/components/organisation/org-profile/profile-info';
import GeneralInformation from '@src/components/organisation/org-profile/general-information';
import { useNavigate } from 'react-router-dom';

const OrganisationProfileComponent = () => {
    const { currentUser } = useAuth();
    const { orgId } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { data, isLoading, error } = useGetOrganisationByIdQuery( Number( orgId ) );

    if( isLoading ) {
        return <LoadingScreen />;
    }

    if ( error ) {
        return <ServerErrorPage />;
    }

    return(
        <ViewGuard
            permission={Permissions.CAN_DELETE_ORGANISATIONS}
            additionalCheck={() => {
                return currentUserCan( currentUser.userProfile.role?.permissions, Permissions.CAN_READ_ORGANISATIONS ) && currentUser.userProfile.organisation.id === Number( orgId );
            }}>
            <AppLayout
                title={t( 'organisations:organisation_profile' ) + ` [${data?.name}]`}
            >

                <div className="grid grid-cols-1 px-4 pt-6 xl:grid-cols-3 xl:gap-4">
                    <div className="col-span-full mb-4 xl:mb-2">
                        <Breadcrumb className="mb-4">
                            <Breadcrumb.Item href="#">
                                <div className="flex items-center gap-x-3">
                                    <HiHome className="text-xl" />
                                    <span className="dark:text-white">Home</span>
                                </div>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item
                                onClick={() => navigate( '/organisation/list' )}
                                className="cursor-pointer"
                            >
                                <div className="flex items-center gap-x-3">
                                    <span className="dark:text-white">{t( 'common:organisations' )}</span>
                                </div>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>{data?.name}</Breadcrumb.Item>
                        </Breadcrumb>
                        <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
                            {t( 'organisations:organisation_profile' )}
                        </h1>
                        {
                            currentUserCan( currentUser.userProfile.role?.permissions, Permissions.CAN_DELETE_ORGANISATIONS ) ||
                            currentUserCan( currentUser.userProfile.role?.permissions, Permissions.CAN_EDIT_CURRENT_ORGANISATION ) && currentUser.userProfile.organisation.id === Number( orgId ) ?
                                <a className="cursor-pointer" onClick={() => navigate( `/organisation/edit/${orgId}` )}>{t( 'common:edit' )}</a> : ''
                        }
                    </div>
                    <div className="col-span-full xl:col-auto">
                        <ProfileIntro
                            organisation={data!}
                        />
                    </div>
                    <div className="col-span-2">
                       <GeneralInformation organisation={data!} />
                    </div>
                </div>
            </AppLayout>
        </ViewGuard>
    );
};

export default OrganisationProfileComponent;