import Joi from 'joi';

const ProfileInformationSchema = Joi.object( {
    firstName: Joi.string()
        .required()
        .messages( {
            'string.empty': 'required_field',
            'any.required': 'required_field',
        } ),
    lastName: Joi.string()
        .required()
        .messages( {
            'string.empty': 'required_field',
            'any.required': 'required_field',
        } ),
    street: Joi.string().allow( '', null ),
    city: Joi.string().allow( '', null ),
    country: Joi.string().allow( '', null ),
    zip: Joi.number()
        .integer()
        .allow( '', null )
        .messages( {
            'number.base': 'valid_number',
            'number.integer': 'valid_number',
        } ),
    phone: Joi.string()
        .pattern( /^\d+$/ )
        .allow( '', null )
        .messages( {
            'string.base': 'valid_phone',
            'string.pattern.base': 'valid_phone',
        } ),
    mobile: Joi.string()
        .pattern( /^\d+$/ )
        .messages( {
            'string.base': 'valid_phone',
            'string.pattern.base': 'valid_phone',
        } )
        .allow( '', null ),
} );

export default ProfileInformationSchema;