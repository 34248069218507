import { List } from 'flowbite-react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const PasswordStrengthIndicator = ( { password }: {password: string} ) => {
    const { t } = useTranslation();
    const requirements = [
        { label: t( 'resetpassword:eight_chars' ), condition: password.length >= 8 },
        { label: t( 'resetpassword:uppercase_letter' ), condition: /[A-Z]/.test( password ) },
        { label: t( 'resetpassword:one_digit' ), condition: /[0-9]/.test( password ) },
        { label: t( 'resetpassword:special_char' ), condition: /[!@#$%^&*()\-__+.]/.test( password ) }
    ];

    return (
        <div>
            <h5 className="mb-6 dark:text-white">{t( 'resetpassword:password_requirements' )}:</h5>
            <List unstyled>
                {requirements.map( ( requirement, index ) => (
                    <List.Item key={index} className={classNames( 'dark:text-white flex mb-2', {
                        'text-green-400': requirement.condition
                    } )}>

                        {requirement.condition ?
                            <svg className={classNames( 'w-6 h-6 mr-2 text-green-400 dark:text-white' )} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M   8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                            </svg>
                            :
                            <svg className="w-6 h-6 mr-2  text-gray-400 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m15 9-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                            </svg>
                        }

                        {requirement.label}
                    </List.Item>
                ) )}
            </List>
        </div>
    );
};

export default PasswordStrengthIndicator;