import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@src/redux/store';
import isSmallScreen from '@src/helpers/is-small-screen';

export interface SidebarState {
    isOpenOnSmallScreens: boolean,
    isPageWithSidebar: boolean,
}


// Define the initial state using that type
const initialState: SidebarState = {
    isOpenOnSmallScreens: isSmallScreen(),
    isPageWithSidebar: true,
};

export const sidebarSlice = createSlice( {
    name: 'sidebar',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setOpenOnSmallScreen: ( state, action: PayloadAction<boolean> ) => {
            state.isOpenOnSmallScreens = action.payload;
        },
        setIsPageWithSidebar: ( state, action: PayloadAction<boolean> ) => {
            state.isPageWithSidebar = action.payload;
        },
    }
} );

export const { setOpenOnSmallScreen, setIsPageWithSidebar } = sidebarSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectIsOpenOnSmallScreens = ( state: RootState ) => state.sidebar.isOpenOnSmallScreens;
export const selectIsPageWithSidebar = ( state: RootState ) => state.sidebar.isPageWithSidebar;

export default sidebarSlice.reducer;