import LoaderSpin from '@src/components/loader/loader-spin';

const ComponentLoading = () => {
    return(
        <div className="absolute top-0 left-0 w-[100%] h-[100%] flex justify-center items-center transition-all duration-75" style={{ backgroundColor: 'rgba(255,255,255,0.7)' }}>
            <LoaderSpin/>
        </div>
    );
};

export default ComponentLoading;