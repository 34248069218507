export const arrayBufferToBase64 = ( buffer: ArrayBuffer ) => {
    let binary = '';
    const bytes = new Uint8Array( buffer );
    const len = bytes.byteLength;
    // eslint-disable-next-line no-plusplus
    for ( let i = 0; i < len; i++ ) {
        // @ts-ignore
        binary += String.fromCharCode( bytes[i] );
    }
    return window.btoa( binary );
};