import { createInstance } from 'i18next';
import { initReactI18next } from 'react-i18next/initReactI18next';
import i18nConfig from '@src/i18nConfig';
import resourcesToBackend from 'i18next-resources-to-backend';

// @ts-ignore
export default function initTranslations(
    // @ts-ignore
    locale,
    // @ts-ignore
    namespaces,
    // @ts-ignore
    i18nInstance?,
    // @ts-ignore
    resources?
) {
    i18nInstance = i18nInstance || createInstance();

    i18nInstance.use( initReactI18next );

    if ( !resources ) {
        // @ts-ignore
        i18nInstance.use(
            resourcesToBackend(
                // @ts-ignore
                ( language, namespace ) =>
                    // @ts-ignore
                    import( `@src/locales/${language}/${namespace}.json` )
            )
        );
    }

     i18nInstance.init( {
        lng: locale,
        resources,
        fallbackLng: i18nConfig.defaultLocale,
        supportedLngs: i18nConfig.locales,
        defaultNS: namespaces[0],
        fallbackNS: namespaces[0],
        ns: namespaces,
        preload: resources ? [] : i18nConfig.locales
    } );

    return {
        i18n: i18nInstance,
        resources: i18nInstance.services.resourceStore.data,
        t: i18nInstance.t
    };
}
