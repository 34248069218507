import { FormErrorState } from '@src/types/form/adva-form-props';

const SetFormErrorInitialState = <T>( inputs: T ) => {

    let initialState: FormErrorState = {};

    for( let input in inputs ) {
        initialState[input as keyof  FormErrorState] = {
            valid: true,
            errorMessageKey: ''
        };
    }

    return initialState;
};

export default SetFormErrorInitialState;