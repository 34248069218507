import {  Label, TextInput } from 'flowbite-react';
import { AdvaTextInputProps } from '@src/types/form/adva-form-props';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const AdvaTextInput = ( { label, name, id, type, placeholder, onChange, className, validation, value, defaultValue, addon, icon, disabled }: AdvaTextInputProps ) => {
    const inputId = id || ( Math.random() + 1 ).toString( 36 ).substring( 7 );
    const { t } = useTranslation();


    const isValid = validation ? validation.valid : true;
    const errorMessageKey = validation ? validation.errorMessageKey : '';

    return (
        <div className={classNames( 'my-1 border-', className )}>
            <Label
                className={classNames( 'mb-2 block', {
                    'text-red-700 dark:text-red-500' : !isValid
                } )}
                htmlFor={inputId}>
                {label}
            </Label>
            <TextInput
                defaultValue={defaultValue}
                disabled={disabled}
                className="border-gray-300 text-gray-900"
                addon={addon}
                icon={icon}
                value={value || ''}
                color={!isValid ? 'failure' : ''}
                id={inputId}
                name={name}
                placeholder={placeholder}
                type={type}
                onChange={onChange}
                helperText={ !isValid && errorMessageKey.length > 0 ? t( `validation_errors:${errorMessageKey}` ) : '' }
            />
        </div>
    );
};

export default AdvaTextInput;