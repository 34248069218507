import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IOrganisation } from '@src/types/organisation/i-organisation';
import { ListResponse } from '@src/types/list-response/list-response';
import objectToQueryString from '@src/helpers/object-to-query-string';
import Cookies from 'js-cookie';
import { isCookieAuthenticated } from '@src/helpers/auth';

interface ArgKey {
    page?: number,
    items?: number,
    search?: string,
    order?: 'asc' | 'desc',
    orderBy?: string
}

export const organisationsService = createApi( {
    reducerPath: 'organisationService',
    baseQuery: fetchBaseQuery( {
        baseUrl: process.env.REACT_APP_BACKEND,
        prepareHeaders: ( headers ) => {
            headers.set( 'Accept-Language', Cookies.get( 'locale' ) || 'de' );
            const token = isCookieAuthenticated();

            if( token ) {
                headers.set( 'Authorization', `Bearer ${token}` );
            }

            return headers;
        },
    } ),
    endpoints: ( builder ) => ( {
        getOrganisationList: builder.query<ListResponse<IOrganisation>, ArgKey >( {
            query( args ) {
                const queryString = objectToQueryString( args );
               return `/organisations/?${queryString}`;
            },
        } ),
        getOrganisationById: builder.query<IOrganisation, number >( {
            query( orgId ) {
                return `/organisations/${orgId}`;
            }
        } )
    }
    )
} );

export  const {
    useGetOrganisationByIdQuery,
    useGetOrganisationListQuery
} = organisationsService;