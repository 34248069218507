import { Alert, Button, Card } from 'flowbite-react';
import AdvaForm from '@src/components/form/adva-form';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
    FormErrorState,
    ProfileInformationProps,
} from '@src/types/form/adva-form-props';
import SetFormErrorInitialState from '@src/helpers/set-form-error-initial-state';
import handleInputChange from '@src/helpers/handle-input-change';
import ComponentLoading from '@src/components/loading-screen/component-loading';
import validateForm from '@src/helpers/validate-form';
import Api from '@src/services/request-service';
import AdvaTextInput from '@src/components/form/inputs/adva-text-input';
import { Profile } from '@src/types/profile/profile';
import { HiDevicePhoneMobile } from 'react-icons/hi2';
import { HiOutlineFlag, HiOutlineGlobe, HiOutlineLocationMarker, HiOutlineOfficeBuilding, HiPhone } from 'react-icons/hi';
import ProfileInformationSchema from '@src/schemas/profile/profile-information-schema';
import { IAccount } from '@src/types/user/i-profile';
import { useAuth } from '@src/context/auth-context';

interface ProfileDataChangeProps {
    adminEdit? : boolean
    userData: IAccount
}

// @ts-ignore
const ProfileDataChange = ( { adminEdit, userData }: ProfileDataChangeProps ) => {

    const initialFormState: ProfileInformationProps = {
        firstName: '',
        lastName: '',
        phone: '',
        mobile: '',
        street: '',
        city: '',
        zip: undefined,
        country: ''
    };

    const { t } = useTranslation( 'resetpassword' );
    const { updateContextProfile } = useAuth();
    const [ formData, setFormData ] = useState<ProfileInformationProps>( initialFormState );
    const [ errors, setErrors ] = useState<FormErrorState>( SetFormErrorInitialState( formData ) );
    const [ serverMessage, setServerMessage ] = useState( { type: '', message: '' } );
    const [ sending, setSending ] = useState( false );

    const handleChange = ( event: any ) => {
        handleInputChange(
            event,
            formData,
            setFormData,
            errors,
            setErrors
        );
    };

    const handleSubmit = async ( event: any ) => {
        event.preventDefault();
        setSending( true );
        setErrors( SetFormErrorInitialState( formData ) );

        const isValid = validateForm<Profile>( ProfileInformationSchema, formData, setErrors );

        if ( !isValid ) {
            setSending( false );
            return;
        }

        try {
            await Api.patch(
                '/users/me/profile',
                {
                    phone: formData.phone,
                    mobile: formData.mobile,
                    address: {
                        street: formData.street,
                        city: formData.city,
                        zip: formData.zip,
                        country: formData.country
                    }
                }
            );

            const userProfileResponse =  await Api.patch(
                '/users/me/account',
                {
                    firstName: formData.firstName,
                    lastName: formData.lastName
                }
            );

             updateContextProfile!( userProfileResponse.data );
            setServerMessage( { type: 'success', message: t( 'common:profile_success_update' ) } );
            setSending( false );
        } catch ( error: any ) {
            setServerMessage( { type: 'failure', message: error.response.data.errorMessage } );
            setSending( false );
            console.log( error.response.data );
        }
    };

    useEffect( () => {
        if( userData ) {
            setFormData( {
                firstName: userData.firstName || '',
                lastName: userData.lastName || '',
                street: userData.profile!.address && userData.profile!.address.street ? userData.profile!.address.street  : '',
                city: userData.profile!.address && userData.profile!.address?.city ? userData.profile!.address?.city : '',
                zip: userData.profile!.address && userData.profile!.address?.zip ? userData.profile!.address?.zip + '' : undefined,
                country: userData.profile!.address && userData.profile!.address?.country ? userData.profile!.address?.country : '',
                phone: userData.profile!.phone || '',
                mobile: userData.profile!.mobile || ''
            } );
        }
    }, [ userData ] );

    return (
        <Card className="relative">
            <h3 className="mb-4 text-xl font-bold dark:text-white">
                {t( 'common:profile_information' )}
            </h3>
            <AdvaForm onSubmit={( e )=> handleSubmit( e )}>
                <div className="grid grid-cols-9 gap-x-6 gap-y-0">

                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <AdvaTextInput
                            key="firstName"
                            validation={errors['firstName']}
                            value={formData.firstName}
                            name="firstName"
                            id="firstName"
                            type="text"
                            label={t( 'common:first_name' )}
                            onChange={( e: any ) => handleChange( e )}
                        />
                    </div>

                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <AdvaTextInput
                            key="lastName"
                            validation={errors['lastName']}
                            value={formData.lastName}
                            name="lastName"
                            id="lastName"
                            type="text"
                            label={t( 'common:last_name' )}
                            onChange={( e: any ) => handleChange( e )}
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <AdvaTextInput
                            disabled={true}
                            key="email"
                            value={userData.email}
                            name="email"
                            id="email"
                            type="text"
                            label={t( 'common:email' )}
                            onChange={( e: any ) => handleChange( e )}
                        />
                    </div>
                </div>
                <div className="grid grid-cols-1 gap-x-6 gap-y-0">
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <AdvaTextInput
                            icon={HiOutlineOfficeBuilding}
                            validation={errors['street']}
                            key="street"
                            value={formData.street}
                            name="street"
                            id="street"
                            type="text"
                            label={t( 'common:street' )}
                            onChange={( e: any ) => handleChange( e )}
                        />
                    </div>
                </div>

                <div className="grid grid-cols-9 gap-x-6 gap-y-0">
                    <div className="col-span-4 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <AdvaTextInput
                            icon={HiOutlineFlag}
                            validation={errors['zip']}
                            key="zip"
                            value={formData.zip }
                            name="zip"
                            id="zip"
                            type="text"
                            label={t( 'common:zip' )}
                            onChange={( e: any ) => handleChange( e )}
                        />
                    </div>
                    <div className="col-span-4 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <AdvaTextInput
                            icon={HiOutlineLocationMarker}
                            validation={errors['city']}
                            key="city"
                            value={formData.city}
                            name="city"
                            id="city"
                            type="text"
                            label={t( 'common:city' )}
                            onChange={( e: any ) => handleChange( e )}
                        />
                    </div>
                    <div className="col-span-4 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <AdvaTextInput
                            icon={HiOutlineGlobe}
                            validation={errors['country']}
                            key="country"
                            value={formData.country}
                            name="country"
                            id="country"
                            type="text"
                            label={t( 'common:country' )}
                            onChange={( e: any ) => handleChange( e )}
                        />
                    </div>
                </div>

                <div className="grid grid-cols-6 gap-x-6 gap-y-0">
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <AdvaTextInput
                            icon={HiPhone}
                            validation={errors['phone']}
                            key="phone"
                            value={formData.phone}
                            name="phone"
                            id="phone"
                            type="text"
                            label={t( 'common:phone' )}
                            onChange={( e: any ) => handleChange( e )}
                        />
                    </div>

                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <AdvaTextInput
                            icon={HiDevicePhoneMobile}
                            validation={errors['mobile']}
                            key="mobile"
                            value={formData.mobile}
                            name="mobile"
                            id="mobile"
                            type="text"
                            label={t( 'common:mobile' )}
                            onChange={( e: any ) => handleChange( e )}
                        />
                    </div>
                </div>

                <div  className="my-6 gap-y-3">
                    <Button disabled={sending} type="submit" className="w-full lg:w-auto">
                        {t( 'common:save' )}
                    </Button>
                </div>
                {
                    serverMessage.message.length > 0 ?
                        <Alert
                            color={serverMessage.type}
                            onDismiss={() => setServerMessage( { message: '', type: '' } )}
                        >
                            {serverMessage.message}
                        </Alert>
                        : ''
                }
            </AdvaForm>

            {sending ?
                <ComponentLoading /> :
                '' }
        </Card>
    );
};

export default ProfileDataChange;