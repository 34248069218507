import AppLayout from '@src/layouts/app-layout';
import { Badge, Breadcrumb, Card } from 'flowbite-react';
import { HiHome, HiOutlineEye, HiOutlinePencil } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import AdvaDataTable, { DataTableColumnHeader, LazyTableState } from '@src/components/data-table/data-table';
import { useGetOrganisationListQuery } from '@src/services/organisations-service';
import { IOrganisation } from '@src/types/organisation/i-organisation';
import { Constants } from '@src/enums/constants';
import {  useEffect, useState } from 'react';
import LoadingScreen from '@src/components/loading-screen/loading-screen';
import ServerErrorPage from '@src/app/error/error';
import { useNavigate } from 'react-router-dom';

const OrganisationsList = () => {
    const { t } = useTranslation();
    const defualtRows = 10;
    const navigate = useNavigate();

    const [ lazyData, setLazyData ] = useState<LazyTableState>( {
        first: 0,
        rows: defualtRows,
        sortField: null,
        sortOrder: null,
        multiSortMeta: [],
        filters: null,
        page: 0,
        totalPages: 0
    } );

    const [ search, setSearch ] = useState( '' );

    const { data, isFetching, isLoading, error, refetch } = useGetOrganisationListQuery( {
        page: lazyData.page + 1,
        items: lazyData.rows,
        search: search
    } );

    const columns: DataTableColumnHeader[] = [
        {
            dataField: 'logo',
            title: '',
            sortable: false,
            width: '65px',
            body: ( data: IOrganisation ) => {
                const img = data.logo && data.logo.length > 0 ? data.logo : Constants.DEFAULT_USER_IMAGE;
                return(
                    <img className="w-8 h-8 transition-all rounded-full" src={img} alt={data.name}/>
                );
            }
        },
        {
            dataField: 'name',
            title: t( 'common:name' ),
            body: ( data ) => {
                return(
                    <div className="flex items-center">
                        <Badge className="w-3 h-4 mr-3" color={data.active ? 'success' : 'failure'}></Badge> {data.name}
                    </div>
                );
            }
        },
        {
            dataField: 'email',
            title:  t( 'common:email' ),
            sortable: false,
            body: ( data: IOrganisation ) => data.email || 'N/A'
        },
        {
            dataField: 'phone',
            title: t( 'common:phone' ),
            sortable: false,
            body: ( data: IOrganisation ) => data.phone || 'N/A'

        },
        {
            dataField: 'mobile',
            title: t( 'common:mobile' ),
            sortable: false,
            body: ( data: IOrganisation ) => data.mobile || 'N/A'
        },
        {
            title: t( 'common:actions' ),
            width: '13%',
            body: ( data: IOrganisation ) => {
                return (
                    <div className="flex">
                        <a
                            target="_blank"
                            className="cursor-pointer max-w-[130px] justify-center items-center flex px-2 py-1 text-gray-500 text-sm border-2 rounded mr-3 border-gray-500"
                            onClick={() => navigate( `/organisation/edit/${data.id}` )}
                        >
                            <HiOutlinePencil/>
                            {t( 'common:edit' )}
                        </a>
                        <a
                            target="_blank"
                            className="cursor-pointer flex max-w-[80px] justify-center items-center px-2 py-1 text-primary-500 text-sm border-2 rounded mr-3 border-primary-500"
                            onClick={() => navigate( `/organisation/${data.id}` )}>
                            <HiOutlineEye/> {t( 'common:profile' )}
                        </a>
                    </div>
                );
            }
        },
    ];

    const handleRowsPerPageChange = ( event: any ) => {
        const value = event.target.value;
        setLazyData( {
            ...lazyData,
            first:0,
            page:0,
            rows: parseInt( value )
        } );
    };

    useEffect( () => {
        refetch();
    }, [ lazyData, search ] );

    if( isLoading ) {
        return <LoadingScreen />;
    }

    if ( error ) {
        return <ServerErrorPage />;
    }

    return(
        <AppLayout
            title={t( 'organisations:organisation_list' )}
        >
            <div className="grid grid-cols-1 gap-y-6 px-4 pt-6 dark:bg-gray-900 xl:grid-cols-3 xl:gap-4">
                <div className="col-span-full">
                    <Breadcrumb className="mb-4">
                        <Breadcrumb.Item
                            className="cursor-pointer"
                            onClick={() => navigate( '/' )}
                        >
                            <div className="flex items-center gap-x-3">
                                <HiHome className="text-xl" />
                                <span className="dark:text-white">{t( 'common:home' )}</span>
                            </div>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <div className="flex items-center gap-x-3">
                                <span className="dark:text-white">{t( 'common:organisations' )}</span>
                            </div>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
                        { t( 'organisations:organisation_list' ) }
                    </h1>
                </div>

                <div className="col-span-full">
                    <Card>
                      <AdvaDataTable
                          search={search}
                          setSearch={setSearch}
                          rowsPerPageChangeOptions={[ 10, 25, 50, 100 ]}
                          rowsPerPageChange={handleRowsPerPageChange}
                          lazy={true}
                          lazyData={lazyData}
                          totalRecords={data?.totalRecordsNumber}
                          columns={columns || []}
                          data={data?.records || []}
                          loading={isFetching}
                          onPageChange={setLazyData}
                      />
                    </Card>
                </div>
            </div>
        </AppLayout>
    );
};

export default OrganisationsList;