import SetFormErrorInitialState from '@src/helpers/set-form-error-initial-state';
// import LoginSchema from '@src/schemas/access/login-schema';
import { FormErrorState } from '@src/types/form/adva-form-props';
import Joi from 'joi';
import { Dispatch, SetStateAction } from 'react';

const validateForm = <T>(
    schema: Joi.ObjectSchema,
    formData: T,
    setErrors: Dispatch<SetStateAction<FormErrorState>>
): boolean => {
    setErrors( SetFormErrorInitialState( formData ) );
    const isValid = schema.validate( formData, { abortEarly: false } );

    if ( isValid.error ) {
        const validationErrors = isValid.error.details;
        const newErrorState: FormErrorState = {};

        validationErrors.forEach( ( err ) => {
            const inputKey = err.context?.key;
            const message = err.message;

            newErrorState[inputKey as keyof FormErrorState] = {
                valid: false,
                errorMessageKey: message
            };
        } );

        setErrors( newErrorState );

        return false;
    }

    return true;
};

export default validateForm;