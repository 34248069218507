const currentUserCan = ( permissions: any[] | undefined, payload: string ) => {

    if ( !permissions ) {
        return undefined;
    }

    return  permissions.some( ( p ) => p.permission === payload );

};

export default currentUserCan;