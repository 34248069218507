import { configureStore, ThunkAction, Action, Store } from '@reduxjs/toolkit';
import  sidebarReducer from '@src/redux/slices/sidebar-slice';
import { organisationsService } from '@src/services/organisations-service';


// @ts-ignore
export const store: Store = configureStore( {
    reducer: {
        sidebar: sidebarReducer,
        [organisationsService.reducerPath]: organisationsService.reducer
    },

    middleware: ( getDefaultMiddleware ) =>
        getDefaultMiddleware().concat( [ organisationsService.middleware ] ),
} );

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;