import { Alert, ToggleSwitch } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { AdvaToggleProps } from '@src/types/form/adva-form-props';

const AdvaToggle = ( { label, name, id, onChange, className, validation, disabled, checked }: AdvaToggleProps ) => {
   const inputId = id || ( Math.random() + 1 ).toString( 36 ).substring( 7 );
   const { t } = useTranslation();


   const isValid = validation ? validation.valid : true;
   const errorMessageKey = validation ? validation.errorMessageKey : '';

   return(
       <div className={classNames( 'my-3 border-', className )}>
          <ToggleSwitch id={inputId} disabled={disabled} name={name} label={label}  checked={checked} onChange={onChange}/>

           {
           !isValid && errorMessageKey.length > 0 ?
               <Alert
                   className="mt-2"
                   color="failure"
               >
                   {t( `validation_errors:${errorMessageKey}` ) }
               </Alert>

               : ''
           }
       </div>
   );
};

export default AdvaToggle;