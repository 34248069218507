
import 'react-advanced-cropper/dist/style.css';
import { Constants } from '@src/enums/constants';
import  {  useState } from 'react';

import Api from '@src/services/request-service';
import { useAuth } from '@src/context/auth-context';
import AvatarCard from '@src/components/avatar/avatar-card';

interface UserProfileCardProps {
    firstName: string;
    lastName: string;
    profileImage?: string | undefined
    departmentRole? : string | undefined
    onChangeImgClick?: any
}

const UserProfileCard = ( { firstName, lastName, profileImage, onChangeImgClick }: UserProfileCardProps ) => {


    const [ profileImg ] = useState( profileImage || Constants.DEFAULT_USER_IMAGE );
    const [ sending, setSending ] = useState( false );
    const [ serverMessage, setServerMessage ] = useState( { type: '', message: '' } );
    const { updateContextProfile, currentUser } = useAuth();


    const handleSaveImage = async ( image: string ) => {
       try {
           setSending( true );
           await Api.patch(
               '/users/me/profile',
               {
                   profileImage: image
               }
           );

           const profileCopy  = { ...currentUser.userProfile };
           profileCopy.profile!.profileImage = image;
           updateContextProfile!( profileCopy );

           setSending( false );
       } catch ( error: any ) {
           setSending( false );
           setServerMessage( { type: 'failure', message: error.response.data.errorMessage } );
           setSending( false );
           console.log( error.response.data );
       }
    };

    const handleErrorSaveImage = ( error:any ) => {
        setSending( false );
        setServerMessage( { type: 'failure', message: error.response.data.errorMessage } );
        setSending( false );
        console.log( error.response.data );
    };

    return (
        <AvatarCard
            title={`${firstName} ${lastName}`}
            loading={sending}
            image={profileImg}
            onSave={handleSaveImage}
            onError={handleErrorSaveImage}
            responseData={serverMessage}
            setResponseData={setServerMessage}
            onChangeImage={onChangeImgClick}
        />
    );
};

export default UserProfileCard;