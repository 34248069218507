import { forwardRef } from 'react';
import { FixedCropper, FixedCropperProps, FixedCropperRef, ImageRestriction } from 'react-advanced-cropper';
import { CustomCropWrapper } from '@src/components/crop-profile-image/custom-crop-wrapper';

export type CustomCropperProps = FixedCropperProps;

export type CustomCropperRef = FixedCropperRef;


export const CustomCropper = forwardRef<CustomCropperRef, CustomCropperProps>(
    ( { stencilProps, ...props }: CustomCropperProps, ref ) => (
        <FixedCropper
            ref={ref}
            stencilProps={{
                handlers: false,
                lines: true,
                movable: false,
                resizable: true,
                ...stencilProps,
            }}
            imageRestriction={ImageRestriction.stencil}
            // @ts-ignore
            wrapperComponent={CustomCropWrapper}
            {...props}
        />
    ),
);

CustomCropper.displayName = 'CustomCropper';