import LoaderSpin from '@src/components/loader/loader-spin';

const LoadingScreen = () => (
    <div
        // style={{
        //     display: 'flex',
        //     position: 'fixed',
        //     left: 0,
        //     right: 0,
        //     top: 0,
        //     bottom: 0,
        //     background: '#2e3142',
        //     zIndex: 99999,
        //     justifyContent: 'center',
        //     alignItems: 'center',
        // }}
        className="flex fixed left-0 right-0 top-0 bottom-0 bg-[#f9fafb] z-[99999] justify-center items-center"
    >
    <LoaderSpin />
    </div>
);

export default LoadingScreen;