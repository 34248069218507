import AdvaTextInput from '@src/components/form/inputs/adva-text-input';
import AdvaForm from '@src/components/form/adva-form';
import { useTranslation } from 'react-i18next';
import AvatarCard from '@src/components/avatar/avatar-card';
import { Alert, Button, Card, TextInput } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { FormErrorState } from '@src/types/form/adva-form-props';
import SetFormErrorInitialState from '@src/helpers/set-form-error-initial-state';
import handleInputChange from '@src/helpers/handle-input-change';
import AdvaToggle from '@src/components/form/inputs/adva-toggle';
import ComponentLoading from '@src/components/loading-screen/component-loading';
import validateForm from '@src/helpers/validate-form';
import OrganisationSchema from '@src/schemas/organisation/organisation-schema';
import Api from '@src/services/request-service';
import { useAuth } from '@src/context/auth-context';
import { useNavigate } from 'react-router-dom';
import { IOrganisation } from '@src/types/organisation/i-organisation';
import { Constants } from '@src/enums/constants';
import { HiOutlineFlag, HiOutlineGlobe, HiOutlineLocationMarker, HiOutlineOfficeBuilding, HiPhone } from 'react-icons/hi';
import { HiDevicePhoneMobile, HiOutlineEnvelopeOpen } from 'react-icons/hi2';
import AdvaTextArea from '@src/components/form/inputs/adva-text-area';

export interface OrganisationFormState {
    name?: string;
    logo?: string;
    active?: boolean;
    street?: string;
    city?: string;
    zip?: string;
    country?: string;
    email?: string;
    phone?: string;
    mobile?: string;
    description?: string;
}

export interface OrganisationFormData {
    organisation?: IOrganisation;
}

const OrganisationForm = ( { organisation }: OrganisationFormData ) => {

    const endpoint = organisation ? `/organisations/${organisation.id}` : '/organisations/add';
    const method = organisation ? 'patch' : 'post';

    const initialFormState: OrganisationFormState = {
        name: '',
        logo: Constants.DEFAULT_USER_IMAGE,
        active: true,
        street: '',
        city: '',
        zip: '',
        country: '',
        email: '',
        phone: '',
        mobile: '',
        description: ''
    };

    const { t } = useTranslation();
    const [ formData, setFormData ] = useState<OrganisationFormState>( initialFormState );
    const [ errors, setErrors ] = useState<FormErrorState>( SetFormErrorInitialState( formData ) );
    const [ serverMessage, setServerMessage ] = useState( { type: '', message: '' } );
    const [ sending, setSending ] = useState( false );
    const { currentUser, updateContextProfile } = useAuth();
    const navigte = useNavigate();

    useEffect( () => {
        if( organisation ) {
            setFormData( {
                name: organisation.name,
                logo: organisation.logo,
                active: organisation.active,
                street: organisation.street,
                city: organisation.city,
                zip: organisation.zip,
                country: organisation.country,
                email: organisation.email,
                phone: organisation.phone,
                mobile: organisation.mobile,
                description: organisation.description
            } );
        }

    }, [ organisation ] );

    const handleImageSave = ( image: string ) => {
        setFormData( { ...formData, logo: image } );
    };

    const handleChange = ( event: any ) => {
        handleInputChange(
            event,
            formData,
            setFormData,
            errors,
            setErrors
        );
    };

    const handleToggleChange = () => {
        console.log( !formData.active );
        setFormData( { ...formData, active: !formData.active } );
    };

    const handleSubmit = async ( event: any ) => {
        event.preventDefault();
        setSending( true );
        setErrors( SetFormErrorInitialState( formData ) );

        const isValid = validateForm<OrganisationFormState>( OrganisationSchema, formData, setErrors );

        if ( !isValid ) {
            setSending( false );
            return;
        }

        try {
            const org = await Api[method]<IOrganisation>(
                endpoint,
                {
                    name: formData.name,
                    active: formData.active,
                    logo: formData.logo,
                    street: formData.street,
                    city: formData.city,
                    zip: formData.zip,
                    country: formData.country,
                    email: formData.email,
                    phone: formData.phone,
                    mobile: formData.mobile,
                    description: formData.description
                }
            );

            if ( orgId ) {
                const user = currentUser.userProfile;

                if( user.organisation.id === orgId ) {
                    updateContextProfile!( {
                        ...user,
                        organisation: org.data
                    } );
                }

                setServerMessage( { type: 'success', message: t( 'common:changes_success_update' ) } );
                setSending( false );
            } else {
                navigte( `/organisation/edit/${org.data.id}` );
            }


        } catch ( error: any ) {
            setServerMessage( { type: 'failure', message: error.response.data.errorMessage } );
            setSending( false );
            console.log( error.response.data );
        }
    };


    return (
        <AdvaForm
            onSubmit={( e )=> handleSubmit( e )}
        >
            {
                serverMessage.message.length > 0 ?
                    <div>
                        <Alert
                            color={serverMessage.type}
                            onDismiss={() => setServerMessage( { message: '', type: '' } )}
                        >
                            {serverMessage.message}
                        </Alert>
                    </div>
                    : ''
            }
            <div className="grid grid-cols-1 gap-y-6 pt-6 dark:bg-gray-900 xl:grid-cols-3 xl:gap-4">
                <div className="col-span-full xl:col-auto">
                    <div className="grid grid-cols-1 gap-y-4">
                        <AvatarCard subTitle={t( 'organisations:select_logo' )} title={t( 'common:logo' )} image={formData.logo as string} onSave={handleImageSave} onError={() => {}} />
                        <TextInput key="logo" value={formData.logo} name="logo" type="hidden" onChange={( e: any ) => handleChange( e )} />

                        <Card className="relative">
                            <h3>{t( 'common:contact_info' )}</h3>
                            <AdvaTextInput
                                icon={HiOutlineEnvelopeOpen}
                                validation={errors['email']}
                                value={formData.email}
                                name="email"
                                id="email"
                                type="text"
                                label={t( 'common:email' )}
                                onChange={( e: any ) => handleChange( e )}
                                key="email"
                            />

                            <AdvaTextInput
                                icon={HiPhone}
                                validation={errors['phone']}
                                key="phone"
                                value={formData.phone}
                                name="phone"
                                id="phone"
                                type="text"
                                label={t( 'common:phone' )}
                                onChange={( e: any ) => handleChange( e )}
                            />

                            <AdvaTextInput
                                icon={HiDevicePhoneMobile}
                                validation={errors['mobile']}
                                key="mobile"
                                value={formData.mobile}
                                name="mobile"
                                id="mobile"
                                type="text"
                                label={t( 'common:mobile' )}
                                onChange={( e: any ) => handleChange( e )}
                            />
                        </Card>
                    </div>
                </div>
                <div className="col-span-2">
                    <Card className="relative">
                        <h3>{t( 'common:details' )}</h3>
                        <AdvaTextInput
                            validation={errors['name']}
                            value={formData.name}
                            name="name"
                            id="name"
                            type="text"
                            label={t( 'common:organisation_name' )}
                            onChange={( e: any ) => handleChange( e )}
                            key="name"
                        />

                        <AdvaTextArea
                            validation={errors['description']}
                            key="description"
                            value={formData.description}
                            name="description"
                            id="description"
                            label={t( 'common:description' )}
                            onChange={( e: any ) => handleChange( e )}
                        />


                        <div className="grid grid-cols-6 gap-x-6 gap-y-0">
                            <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                                <AdvaTextInput
                                    icon={HiOutlineOfficeBuilding}
                                    validation={errors['street']}
                                    value={formData.street}
                                    name="street"
                                    id="street"
                                    type="text"
                                    label={t( 'common:street' )}
                                    onChange={( e: any ) => handleChange( e )}
                                    key="street"
                                />
                            </div>
                            <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                                <AdvaTextInput
                                    icon={HiOutlineFlag}
                                    validation={errors['zip']}
                                    value={formData.zip}
                                    name="zip"
                                    id="zip"
                                    type="text"
                                    label={t( 'common:zip' )}
                                    onChange={( e: any ) => handleChange( e )}
                                    key="zip"
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-6 gap-x-6 gap-y-0">
                            <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                                <AdvaTextInput
                                    icon={HiOutlineLocationMarker}
                                    validation={errors['city']}
                                    value={formData.city}
                                    name="city"
                                    id="city"
                                    type="text"
                                    label={t( 'common:city' )}
                                    onChange={( e: any ) => handleChange( e )}
                                    key="city"
                                />
                            </div>
                            <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                                <AdvaTextInput
                                    icon={HiOutlineGlobe}
                                    validation={errors['country']}
                                    value={formData.country}
                                    name="country"
                                    id="country"
                                    type="text"
                                    label={t( 'common:country' )}
                                    onChange={( e: any ) => handleChange( e )}
                                    key="country"
                                />
                            </div>
                        </div>

                        <AdvaToggle
                            validation={errors['active']}
                            name="active"
                            id="active"
                            label={t( 'common:active' )}
                            onChange={handleToggleChange}
                            checked={formData.active as boolean}
                        />
                    </Card>
                </div>
                <Card className="col-span-full">
                    <div className="ml-auto">
                        <Button disabled={sending} type="submit" className="w-full lg:w-auto">
                            {t( 'common:save' )}
                        </Button>
                    </div>
                </Card>
            </div>
            {sending ?
                <ComponentLoading /> :
                '' }
        </AdvaForm>
    );
};

export default OrganisationForm;