import { useState } from 'react';
import AdvaTextInput from '@src/components/form/inputs/adva-text-input';
import { AdvaTextInputProps } from '@src/types/form/adva-form-props';

const AdvaPasswordInput = ( { label, name, id, onChange, className, validation, value, addon }: AdvaTextInputProps ) => {

    const [ showPassword, setShowPassword ] = useState( false );
    const newPassType = showPassword ? 'text' : 'password';

    const renderPasswordIcon = ( showPassword: boolean ) => {
        return (
            <a href="#" onClick={( e:any ) => {
                e.preventDefault();
                // @ts-ignore
                setShowPassword( !showPassword );
            }}>
                {
                    !showPassword ?
                        <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                             fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" strokeWidth="2"
                                  d="M21 12c0 1.2-4 6-9 6s-9-4.8-9-6c0-1.2 4-6 9-6s9 4.8 9 6Z"/>
                            <path stroke="currentColor" strokeWidth="2" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                        </svg>
                        :
                        <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                             fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M4 14c-.5-.6-.9-1.3-1-2 0-1 4-6 9-6m7.6 3.8A5 5 0 0 1 21 12c0 1-3 6-9 6h-1m-6 1L19 5m-4 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                        </svg>
                }
            </a>
        );
    };

    return (
        <AdvaTextInput
            addon={addon || renderPasswordIcon( showPassword )}
            validation={validation}
            value={value}
            className={className}
            id={id}
            name={name}
            placeholder="••••••••"
            type={newPassType}
            label={label}
            onChange={onChange}
        />
    );

};

export default AdvaPasswordInput;