import { AuthContextType, IAuthContextProvider, IUserAuthInfo, } from '@src/types/context/context';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { isCookieAuthenticated, setCookieAuthToken, unsetCookieAuthToken } from '@src/helpers/auth';

import Api from '@src/services/request-service';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingScreen from '@src/components/loading-screen/loading-screen';
const authContextDefaultValues: AuthContextType = {
    isAuthenticated: false,
    currentUser: { authToken: false, userProfile: {} },
    login: () => {
    },
    logout: () => {
    },
    logoutAll: () => {
    },
    isLoading: true,
    authError: false,
    setAuthError: () => {
    },
};

const AuthContext = createContext<AuthContextType>( authContextDefaultValues );

export const AuthProvider = ( { children }: IAuthContextProvider ) => {

    const [ currentUser, setUser ] = useState<IUserAuthInfo>( { authToken: false, userProfile: {} } );
    const [ isLoading, setLoading ] = useState( true );
    const [ authError, setAuthError ] = useState( '' );
    const navigate = useNavigate();

    useEffect( () => {
        const loadUserFromCookies = async () => {
            const token = isCookieAuthenticated();

            if ( token ) {
                Api.defaults.headers['Authorization'] = `Bearer ${token}`;
                try {
                    const userObj = await Api.get( '/users/me' );
                    if ( userObj ) {setUser( { authToken: token, userProfile: userObj.data } );}
                } catch ( err: any ) {
                    if ( err.response.data.statusCode === 401 ) {
                        unsetCookieAuthToken();
                        setUser( { authToken: false, userProfile: {} } );
                        delete Api.defaults.headers['Authorization'];
                        navigate( '/access' );
                    } else {
                        console.log( err.response.data );
                    }
                }
            }

            setLoading( false );
        };

        loadUserFromCookies();
    }, [] );

    const login = async ( email: string, password: string ) => {
        try {
            setAuthError( '' );
            const data: any = await Api.post( 'auth/signin', { email, password } );
            const { token } = data.data;

            if ( token ) {
                setCookieAuthToken( token.token );
                Api.defaults.headers['Authorization'] = `Bearer ${token.token}`;
                const userObj = await Api.get( 'users/me' );
                setUser( { authToken: token.token, userProfile: userObj.data } );
                return navigate( '/' );
            }
        } catch ( err: any ) {
            const { errorMessage } = err.response.data;
            setAuthError( errorMessage );
            setTimeout( () => {
                setAuthError( '' );
            }, 5000 );
        }
    };

    const logout = () => {
        Api.post( '/auth/signout' ).then( () => {
            unsetCookieAuthToken();
            setUser( { authToken: false, userProfile: {} } );
            delete Api.defaults.headers['Authorization'];
            return navigate( '/access' );
        } )
            .catch( ( err ) => {
                console.log( err.response.data );
            } );
    };

    const logoutAll = () => {
        Api.post( '/auth/signoutAll' ).then( () => {
            unsetCookieAuthToken();
            setUser( { authToken: false, userProfile: {} } );
            delete Api.defaults.headers['Authorization'];
            return navigate( '/access' );
        } )
            .catch( ( err ) => {
                console.log( err.response.data );
            } );
    };

    const updateContextProfile = ( payload: any ) => {
        const contextUser = { ...currentUser };
        contextUser.userProfile = payload;
        setUser( contextUser );
    };


    const value: AuthContextType = {
        isAuthenticated: !!currentUser.authToken,
        currentUser,
        login,
        logout,
        logoutAll,
        updateContextProfile,
        isLoading,
        authError,
        setAuthError
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext( AuthContext );
export const ProtectRoute = ( { children }: { children: ReactNode | any } ) => {
    const { isAuthenticated, isLoading } = useAuth();
    const location = useLocation();
    const { pathname } = location;
    const navigate = useNavigate();


    if ( isAuthenticated && pathname.includes( '/access' ) ) {
       return navigate( '/' );
    }

    if ( isLoading ) {
        return <LoadingScreen/>;
    }

    if ( !isAuthenticated && !pathname.includes( '/access' ) ) {
        return navigate( '/access' );
    }

    return children;
};
