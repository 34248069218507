import Joi from 'joi';

const LoginSchema = Joi.object( {
    email: Joi.string()
        .email( { tlds: { allow: false } } )
        .required()
        .messages( {
            'string.empty': 'required_field',
            'any.required': 'required_field',
            'string.email': 'valid_email'
        } ),
    password: Joi.string()
        .required()
        .messages( {
            'string.empty': 'required_field',
            'any.required': 'required_field'
        } )
} );

export default LoginSchema;