import Joi from 'joi';

const OrganisationSchema = Joi.object( {
    name: Joi.string()
        .required()
        .messages( {
            'string.empty': 'required_field',
            'any.required': 'required_field',
        } ),
    active: Joi.boolean().valid(),
    logo: Joi.string().allow( '', null ),
    street: Joi.string().allow( '', null ),
    city: Joi.string().allow( '', null ),
    zip: Joi.number()
        .integer()
        .allow( '', null )
        .messages( {
            'number.base': 'valid_number',
            'number.integer': 'valid_number',
        } ),
    country: Joi.string().allow( '', null ),
    email: Joi.string()
        .email( { tlds: { allow: false } } )
        .allow( '', null )
        .messages( {
            'string.email': 'valid_email'
        } ),
    phone: Joi.string()
        .pattern( /^\d+$/ )
        .allow( '', null )
        .messages( {
            'string.base': 'valid_phone',
            'string.pattern.base': 'valid_phone',
        } ),
    mobile: Joi.string()
        .pattern( /^\d+$/ )
        .allow( '', null )
        .messages( {
            'string.base': 'valid_phone',
            'string.pattern.base': 'valid_phone',
        } ),
    description: Joi.string().allow( '', null ),
} );

export default OrganisationSchema;