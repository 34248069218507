import Cookies from 'js-cookie';

export const isCookieAuthenticated = (): string | boolean => {
    const token = Cookies.get( 'authToken' );
    return token || false;
};

export const setCookieAuthToken = ( authToken: string ): void => {
    Cookies.set( 'authToken', authToken, { expires: 1, path: '/', secure: true } );
};

export const unsetCookieAuthToken = (): void => {
    Cookies.remove( 'authToken' );
};