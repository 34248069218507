import axios from 'axios';
import Cookies from 'js-cookie';

const Api = axios.create( {
    // baseURL: urls[process.env.NODE_ENV as keyof UrlProps],
    baseURL: process.env.REACT_APP_BACKEND,

    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }
} );

Api.interceptors.request.use( config => {
    config.headers['Accept-Language'] = Cookies.get( 'locale' ) || 'de';
    return config;
} );

export default Api;