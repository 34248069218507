import { Alert, Button, Card } from 'flowbite-react';

import AdvaForm from '@src/components/form/adva-form';
import AdvaTextInput from '@src/components/form/inputs/adva-text-input';
import { useState } from 'react';
import { FormErrorState, ResetPasswordProps } from '@src/types/form/adva-form-props';
import SetFormErrorInitialState from '@src/helpers/set-form-error-initial-state';
import handleInputChange from '@src/helpers/handle-input-change';
import validateForm from '@src/helpers/validate-form';
import { ChangePasswordSchema } from '@src/schemas/access/forgot-password-schema';
import PasswordStrengthIndicator from '@src/components/form/password-strength-indicator';
import Api from '@src/services/request-service';
import ComponentLoading from '@src/components/loading-screen/component-loading';
import TranslationNamespace from '@src/enums/translation-namespace';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const ResetPasswordComponent = () => {
    const [ formData, setFormData ] = useState<ResetPasswordProps>( { newPassword: '', confirmNewPassword: '' } );
    const [ errors, setErrors ] = useState<FormErrorState>( SetFormErrorInitialState( formData ) );
    const [ showPassword, setShowPassword ] = useState( { newPassword: false, confirmNewPassword: false } );
    const [ serverMessage, setServerMessage ] = useState( { type: '', message: '' } );
    const [ sending, setSending ] = useState( false );
    const { verificationToken } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation( [ TranslationNamespace.RESET_PASSWORD, TranslationNamespace.COMMON, TranslationNamespace.VALIDATION_ERRORS ] );

    const newPassType = showPassword.newPassword ? 'text' : 'password';
    const confirmNewPassType = showPassword.confirmNewPassword ? 'text' : 'password';

    const renderPasswordIcon = ( input: string, visible: boolean ) => {
        return (
            <a href="#" onClick={( e:any ) => {
                e.preventDefault();
                const showPwd = { ...showPassword };
                // @ts-ignore
                showPwd[input] = !visible;
                setShowPassword( showPwd );
            }}>
                {
                    !visible ?
                    <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                         fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeWidth="2"
                              d="M21 12c0 1.2-4 6-9 6s-9-4.8-9-6c0-1.2 4-6 9-6s9 4.8 9 6Z"/>
                        <path stroke="currentColor" strokeWidth="2" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                    </svg>
                    :
                    <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                         fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="M4 14c-.5-.6-.9-1.3-1-2 0-1 4-6 9-6m7.6 3.8A5 5 0 0 1 21 12c0 1-3 6-9 6h-1m-6 1L19 5m-4 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                    </svg>
                }
            </a>
        );
    };


    const handleChange = ( event: any ) => {
        handleInputChange(
            event,
            formData,
            setFormData,
            errors,
            setErrors
        );
    };

    const handleSubmit = ( event: any ) => {
        event.preventDefault();
        setSending( true );
        setErrors( SetFormErrorInitialState( formData ) );

        const isValid = validateForm<ResetPasswordProps>( ChangePasswordSchema, formData, setErrors );

        if ( !isValid ) {
            setSending( false );
            return;
        }

        Api.patch( 'auth/changePassword', { password: formData.newPassword, repeatPassword: formData.confirmNewPassword, verificationToken } )
            .then( ( response ) => {
                setServerMessage( { type: 'success', message: response.data.message } );
                setSending( false );
                setFormData( {
                    ...formData,
                    newPassword: '',
                    confirmNewPassword: ''
                } );
            } )
            .catch( ( error ) => {
                setServerMessage( { type: 'failure', message: error.response.data.errorMessage } );
                setSending( false );
            } );
    };

    return (
        <div className="flex flex-col items-center justify-center px-6 lg:h-screen lg:gap-y-12">
            <a onClick={() => navigate( '/' )} className="cursor-pointer my-6 flex items-center gap-x-1 lg:my-0">
                <img
                    alt="Flowbite logo"
                    src="/images/advacare2.svg"
                    className="mr-3 h-10"
                />
            </a>
            <Card
                horizontal
                imgSrc="/images/authentication/reset-password.jpg"
                imgAlt=""
                className="w-full md:max-w-[1024px] md:[&>*]:w-full md:[&>*]:p-16 [&>img]:hidden md:[&>img]:w-96 md:[&>img]:p-0 lg:[&>img]:block relative"
            >
                <h1 className="mb-3 text-2xl font-bold dark:text-white md:text-3xl">
                    {t( 'pageTitle' )}
                </h1>

                <AdvaForm onSubmit={( e: any ) => handleSubmit( e )}>
                    <AdvaTextInput
                        addon={renderPasswordIcon( 'newPassword', showPassword.newPassword )}
                        validation={errors['newPassword']}
                        value={formData.newPassword}
                        className="mb-6 flex flex-col gap-y-3"
                        id="newPassword"
                        name="newPassword"
                        placeholder="••••••••"
                        type={newPassType}
                        label={t( 'new_password' )}
                        onChange={( e: any ) => handleChange( e )}
                    />
                    <AdvaTextInput
                        addon={renderPasswordIcon( 'confirmNewPassword', showPassword.confirmNewPassword )}
                        validation={errors['confirmNewPassword']}
                        value={formData.confirmNewPassword}
                        className="mb-6 flex flex-col gap-y-3"
                        id="confirmNewPassword"
                        name="confirmNewPassword"
                        placeholder="••••••••"
                        type={confirmNewPassType}
                        label={t( 'confirm_password' )}
                        onChange={( e: any ) => handleChange( e )}
                    />
                    <div  className="mb-6 flex flex-col gap-y-3">
                        <Button disabled={sending} type="submit" className="w-full lg:w-auto">
                            {t( 'common:reset_password_btn' )}
                        </Button>
                    </div>
                    <div className="text-right">
                        <a
                            onClick={() => navigate( '/access' )}
                            className="cursor-pointer text-right text-sm  text-primary-600 dark:text-primary-300"
                        >
                            {t( 'common:back_to_login_btn' )}
                        </a>
                    </div>
                </AdvaForm>

                <PasswordStrengthIndicator password={formData.newPassword} />
                {
                    serverMessage.message.length > 0 ?
                        <Alert
                            color={serverMessage.type}
                            onDismiss={() => setServerMessage( { message: '', type: '' } )}
                        >
                            {serverMessage.message}
                        </Alert>
                        : ''
                }
                {sending ?
                    <ComponentLoading /> :
                    '' }
            </Card>
        </div>
    );
};

export default ResetPasswordComponent;