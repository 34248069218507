import { Breadcrumb } from 'flowbite-react';
import { HiHome } from 'react-icons/hi';
import UserProfileCard from '@src/components/profile/user-profile-card';
import { useAuth } from '@src/context/auth-context';
import UserPasswordChange from '@src/components/profile/user-password-change';
import SignOutAll from '@src/components/profile/sign-out-all';
import ProfileDataChange from '@src/components/profile/profile-data-change';
import AppLayout from '@src/layouts/app-layout';
import { useTranslation } from 'react-i18next';

const ProfileSettingsComponent = () => {
    const { currentUser } = useAuth();
    const { t } = useTranslation();
    return (
        <AppLayout title={t( 'pageTitle' )}>
            <div className="grid grid-cols-1 gap-y-6 px-4 pt-6 dark:bg-gray-900 xl:grid-cols-3 xl:gap-4">
                <div className="col-span-full">
                    <Breadcrumb className="mb-4">
                        <Breadcrumb.Item href="/">
                            <div className="flex items-center gap-x-3">
                                <HiHome className="text-xl" />
                                <span className="dark:text-white">{t( 'common:home' )}</span>
                            </div>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {t( 'common:profile_settings_title' )}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
                        { t( 'common:profile_settings_title' ) }
                    </h1>
                </div>
                <div className="col-span-full xl:col-auto">
                    <div className="grid grid-cols-1 gap-y-4 ">
                        <UserProfileCard
                            firstName={currentUser.userProfile.firstName!}
                            lastName={currentUser.userProfile.lastName!}
                            profileImage={currentUser.userProfile.profile?.profileImage}
                            departmentRole="CEO"
                        />
                        <UserPasswordChange />
                        <SignOutAll/>
                    </div>
                </div>

                <div className="col-span-2">
                    <div className="grid grid-cols-1 gap-y-4">
                        <ProfileDataChange
                            adminEdit={false}
                            userData={currentUser.userProfile}
                        />
                    </div>
                </div>
            </div>
        </AppLayout>
    );
};

export default ProfileSettingsComponent;