import { ReactNode, Suspense } from 'react';
import { Provider } from 'react-redux';
import { store } from '@src/redux/store';
import { Flowbite } from 'flowbite-react';
import LoadingScreen from '@src/components/loading-screen/loading-screen';
import flowbiteTheme from '@src/flowbite-theme';
import { AuthProvider, ProtectRoute } from '@src/context/auth-context';
import { PrimeReactProvider } from 'primereact/api';
import Tailwind from 'primereact/passthrough/tailwind';
interface ProvidersProps {
    children: ReactNode | ReactNode[]
}

const Providers = ( { children }: ProvidersProps ) => {

    return (
        <AuthProvider>
            <ProtectRoute>
                <Provider store={store}>
                    <PrimeReactProvider value={{ unstyled: true, pt: Tailwind }} >
                    <Flowbite theme={{ theme: flowbiteTheme }}>
                            <Suspense fallback={<LoadingScreen/>}>
                                {children}
                            </Suspense>
                    </Flowbite>
                    </PrimeReactProvider>
                </Provider>
            </ProtectRoute>
        </AuthProvider>
    );
};

export default Providers;