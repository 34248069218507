import classNames from 'classnames';
import { ReactNode } from 'react';
import MainFooter from '@src/components/main-footer/main-footer';
import { useAppSelector } from '@src/hooks/redux-hooks';
import { selectIsOpenOnSmallScreens } from '@src/redux/slices/sidebar-slice';

interface MainContentProps {
    children: ReactNode
    isFooter?: boolean
}

const MainContent = ( { children, isFooter }: MainContentProps ) => {
    // const { isOpenOnSmallScreens: isSidebarOpen } = useSidebarContext();
    const isSidebarOpen = useAppSelector( selectIsOpenOnSmallScreens );

    return (
        <main
            className={classNames(
                'overflow-y-auto relative w-full h-full bg-gray-50 dark:bg-gray-900',
                isSidebarOpen ? 'lg:ml-0' : 'lg:ml-64'
            )}
        >
            {children}
            {isFooter && (
                <div className="mx-4 mt-4">
                    <MainFooter />
                </div>
            )}
        </main>
    );
};

export default MainContent;