import { CSSProperties, FunctionComponent, ReactNode } from 'react';
import cn from 'classnames';
import { CropperFade, CropperRef } from 'react-advanced-cropper';
import { getAbsoluteZoom, getZoomFactor } from 'advanced-cropper/extensions/absolute-zoom';
import { RangeSlider } from 'flowbite-react';

interface Props {
    cropper: CropperRef;
    children: ReactNode;
    loading: boolean;
    loaded: boolean;
    className?: string;
    style?: CSSProperties;
    callback: Function
}

export const CustomCropWrapper: FunctionComponent<Props> = ( {
                                                                cropper,
                                                                children,
                                                                loaded,
                                                                className,
                                                            } ) => {
    const state = cropper.getState();
    const settings = cropper.getSettings();
    const zoomLevel = getAbsoluteZoom( state, settings );
    const onZoom = ( __: any, value: number, cropSettings: any, cropState: any ) => {
        cropper.zoomImage( getZoomFactor( cropState, cropSettings, value ), { transitions: true } );
    };

    return (
        <>
            <CropperFade
                className={cn( 'custom-wrapper', className )}
                visible={state && loaded}>
                {children}
            </CropperFade>

            <RangeSlider min={0} max={0.8} step={0.001} value={zoomLevel}
                    onChange={( event: any ) => onZoom( event, event.target.value, settings, state )}
                    aria-label="Zoom Level"/>

        </>
    );
};