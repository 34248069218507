import {
    DataTable,
    DataTablePageEvent,
    DataTableSortEvent,
    DataTableFilterEvent, DataTableFilterMeta,
} from 'primereact/datatable';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { ChangeEventHandler, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Paginator } from 'primereact/paginator';
import { Select } from 'flowbite-react';
import { HiSearch } from 'react-icons/hi';
import AdvaTextInput from '@src/components/form/inputs/adva-text-input';

export interface DataTableColumnHeader {
    dataField?: string;
    title: string;
    sortable?: boolean;
    body?: ReactNode | ( ( data: any, options: ColumnBodyOptions ) => ReactNode );
    width?: string;
}

interface AdvaDataTableProps {
    columns: DataTableColumnHeader[];
    loading?: boolean;
    data: any;
    totalRecords?: number;
    lazy?: boolean;
    lazyData: LazyTableState
    onPageChange?: Function;
    rowsPerPageChange : ChangeEventHandler<HTMLSelectElement>
    rowsPerPageChangeOptions?: number[];
    search?: string;
    setSearch?: Function;
}

export interface LazyTableState {
    first: number;
    rows: number;
    page: number;
    sortField?: string | null;
    sortOrder?: number | null;
    multiSortMeta?: any | null;
    filters?: DataTableFilterMeta | null;
    totalPages: number;
}

const AdvaDataTable = ( { columns, data, loading, totalRecords, lazy=false, lazyData, search, setSearch, onPageChange, rowsPerPageChange, rowsPerPageChangeOptions }: AdvaDataTableProps ) => {
    const { t } = useTranslation();
    const perPageOptions = rowsPerPageChangeOptions || [ 10, 25, 50, 100 ];
    // @ts-ignore
    const onPage = ( event: DataTablePageEvent ) => {

        if( typeof onPageChange === 'function' ) {
            console.log( event );
            onPageChange( event );
        }
    };

    // @ts-ignore
    const onSort = ( event: DataTableSortEvent ) => {
    };

    // @ts-ignore
    const onFilter = ( event: DataTableFilterEvent ) => {
    };


    const dataColumns = columns.map( ( col, index ) => {
        return (
            <Column
                key={col.title+col.dataField+index}
                field={col.dataField}
                header={col.title}
                sortable={col.sortable}
                body={col.body}
                headerStyle={{ width: `${col.width || 'auto'}` }}
            />
        );
    } );

    const renderSearch = () => {
        if ( setSearch && typeof setSearch === 'function' ) {
            return (
                <div className="flex mb-10">
                    <AdvaTextInput
                        className="ml-auto"
                        icon={HiSearch}
                        value={search}
                        name="search" id="search"
                        onChange={( e: any ) => {
                            const value = e.target.value;
                            setSearch( value );
                        }
                        }
                    />
                </div>
            );
        }

        return '';
    };

    return(
       <div>

           {renderSearch()}
           <DataTable
               className="adva_data_table"
               rowHover
               value={data || []}
               lazy={lazy}
               dataKey="id"
               first={lazyData.first}
               rows={lazyData.rows}
               totalRecords={totalRecords || 0}
               onPage={onPage}
               onSort={onSort}
               onFilter={onFilter}
               loading={loading}
               tableStyle={{ minWidth: '75rem' }}
           >
               {dataColumns}
           </DataTable>
          <div className="flex mt-4 items-center">
              <Paginator
                  first={lazyData.first}
                  rows={lazyData.rows}
                  totalRecords={totalRecords || 0}
                  onPageChange={onPage}

              />
              <div className="ml-auto text-gray-500 dark:text-white flex items-center">
                  <div>
                      {`${t( 'common:showing_pagination' )} ${lazyData.first + 1} ${t( 'common:to_pagination' )} ${Math.min( lazyData.first + lazyData.rows, totalRecords! )} ${t( 'common:of_pagination' )} ${totalRecords} ${t( 'common:results_pagination' )}`}
                  </div>
                  <div className="ml-5">
                       <Select onChange={rowsPerPageChange}>
                           {
                               perPageOptions.map( ( option ) => <option key={option} value={option}>{option}</option> )
                           }
                       </Select>
                  </div>
              </div>
          </div>
       </div>
    );
};

export default AdvaDataTable;