import {  HiCog, HiLogout } from 'react-icons/hi';
import { Tooltip } from 'flowbite-react';
import LanguageDropdown from '@src/components/sidebar/language-dropdown';
import { useAuth } from '@src/context/auth-context';
import { useTranslation } from 'react-i18next';

const BottomMenu =  () => {
    const { logout } = useAuth();
    const { t } = useTranslation();



    return (
        <div className="flex items-center justify-center gap-x-5">
            <div>
                <Tooltip content={t( 'common:profile_settings_title' )}>
                    <a
                        href="/profile-settings"
                        className="inline-flex cursor-pointer justify-center rounded p-2 text-gray-500 hover:bg-gray-100 hover:text-gray-900 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                        <span className="sr-only">{t( 'common:profile_settings_title' )}</span>
                        <HiCog className="text-2xl text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white" />
                    </a>
                </Tooltip>
            </div>
            <div>
                <LanguageDropdown />
            </div>
            <div>
                <Tooltip content={t( 'common:sign_out' )}>
                    <a
                        onClick={logout}
                        href="#"
                        className="inline-flex cursor-pointer justify-center rounded p-2 text-gray-500 hover:bg-gray-100 hover:text-gray-900 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                        <span className="sr-only">{t( 'common:sign_out' )}</span>
                        <HiLogout  className="text-2xl text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white" />
                    </a>
                </Tooltip>
            </div>
        </div>
    );
};

export default BottomMenu;