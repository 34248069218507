import { HiMap } from 'react-icons/hi';
import {  OrganisationIntroProps } from '@src/types/organisation/i-organisation';
import { Constants } from '@src/enums/constants';
import { useTranslation } from 'react-i18next';

const ProfileIntro = ( { organisation }: OrganisationIntroProps ) => {

    const logoImg = organisation?.logo || Constants.DEFAULT_USER_IMAGE;
    const { t } = useTranslation();

    console.log( organisation );

    return (
        <div className="mb-4 rounded-lg bg-white p-4 shadow dark:bg-gray-800 sm:p-6 xl:p-8">
            <div className="sm:flex sm:space-x-4 xl:block xl:space-x-0">
                <img
                    alt="Logo"
                    src={logoImg}
                    className="mb-2 h-20 w-20 rounded-lg"
                />
                <div>
                    <h2 className="text-xl font-bold dark:text-white">{organisation?.name}</h2>

                    {
                        organisation.city && organisation.country ?
                            <ul className="mt-2 space-y-1">
                                <li className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
                                    <HiMap className="mr-2 text-lg text-gray-900 dark:text-gray-100" />
                                    {organisation.city}, {organisation.country}
                                </li>
                            </ul>
                            : ''
                    }
                </div>
            </div>
            <div className="sm:flex xl:block xl:space-y-4">
                <div className="sm:flex-1">
                    <address className="text-sm font-normal not-italic text-gray-500 dark:text-gray-400">
                        <div className="mt-4">{t( 'common:email' )}</div>
                        <span>{organisation.email || 'N/A'}</span>
                        <div className="mt-4">{t( 'common:address' )}</div>
                        {
                            organisation.street && organisation.country && organisation.city && organisation.zip ?
                                <div className="mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                    {organisation.street}, {organisation.zip}, {organisation.city}, <br />
                                    {organisation.country}
                                </div> :
                                'N/A'
                        }
                        <div className="mt-4">{t( 'common:phone' )}</div>
                        <div className="mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            {organisation.phone || 'N/A'}
                        </div>
                        <div className="mt-4">{t( 'common:mobile' )}</div>
                        <div className="mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            {organisation.mobile || 'N/A'}
                        </div>
                    </address>
                </div>
                <div className="hidden sm:flex-1">
                    <h3 className="mb-2 text-base font-bold text-gray-900 dark:text-white">
                        {t( 'common:about' )}
                    </h3>
                    <p className="text-sm font-normal text-gray-500 dark:text-gray-400">
                        {organisation.description || 'N/A'}
                    </p>

                </div>
            </div>
        </div>
    );
};

export default ProfileIntro;