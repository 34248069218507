const objectToQueryString = ( obj: { [key: string]: any } ): string => {
    const params = new URLSearchParams();
    for ( const key in obj ) {
        if ( Object.hasOwnProperty.call( obj, key ) ) {
            params.append( key, obj[key] );
        }
    }
    return params.toString();
};

export default objectToQueryString;