enum TranslationNamespace {
    COMMON = 'common',
    VALIDATION_ERRORS = 'validation_errors',
    RESET_PASSWORD = 'resetpassword',
    NOT_FOUND = 'notfound',
    LOGIN = 'login',
    HOME = 'home',
    FORGOT_PASSWORD = 'forgotpassword',
    PROFILE_SETTINGS = 'profile_settings',
    ORGANISATIONS = 'organisations',
    ERROR = 'error'
}

export default TranslationNamespace;