import { AvatarProps } from '@src/types/avatar/avatar-props';
import { useNavigate } from 'react-router-dom';

const Avatar = ( { img, alt, id, size=8, navigate }: AvatarProps ) => {
    const imgUrl = img || '/images/user-placeholder.png';
    const altTxt = alt || 'Avatar';
    const imgId = id || '';
    const orgImgSize = `w-${size} h-${size}`;
    const cursorPointer = navigate && navigate.length > 0 ? 'cursor-pointer' : '';
    const nav = useNavigate();

    return (
        <img
            onClick={() => {
                navigate ? nav( navigate ) : false;
            }}
            id={imgId}
            className={[ cursorPointer, orgImgSize, 'transition-all rounded-full' ].join( ' ' )}
            src={imgUrl}
            alt={altTxt}
        />
    );
};

export default Avatar;