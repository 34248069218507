import { Label, Textarea } from 'flowbite-react';
import { AdvaTextInputProps } from '@src/types/form/adva-form-props';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const AdvaTextArea = ( { label, name, id, placeholder, onChange, className, validation, value, defaultValue, disabled, rows=4 }: AdvaTextInputProps ) => {
    const inputId = id || ( Math.random() + 1 ).toString( 36 ).substring( 7 );
    const { t } = useTranslation();

    const isValid = validation ? validation.valid : true;
    const errorMessageKey = validation ? validation.errorMessageKey : '';

    return(
        <div>
            <div className="mb-2 block">
                <Label htmlFor={name} value={label} />
            </div>
            <Textarea
                onChange={onChange}
                value={value || ''}
                defaultValue={defaultValue}
                className={classNames( 'w-', className )}
                id={inputId}
                name={name}
                placeholder={placeholder}
                disabled={disabled}
                helperText={ !isValid && errorMessageKey.length > 0 ? t( `validation_errors:${errorMessageKey}` ) : '' }
                rows={rows}
            />
        </div>
    );
};

export default AdvaTextArea;