import classNames from 'classnames';
import { Sidebar } from 'flowbite-react';

import {
  selectIsOpenOnSmallScreens,
} from '@src/redux/slices/sidebar-slice';

import BottomMenu from '@src/components/sidebar/bottom-menu';
import { useAppSelector } from '@src/hooks/redux-hooks';
import OrganisationBadge from '@src/components/organisation-badge/organisation-badge';
import { useAuth } from '@src/context/auth-context';
import { useTranslation } from 'react-i18next';
import { router } from '@src/routes/router';
import { activeMenu } from '@src/helpers/active-menu';
import currentUserCan from '@src/helpers/current-user-can';
import { useLocation, useNavigate } from 'react-router-dom';

const ExampleSidebar = () => {

  const { t } = useTranslation();
  const isSidebarOpenOnSmallScreens = useAppSelector( selectIsOpenOnSmallScreens );

  const { currentUser } = useAuth();

    const location = useLocation();
    const { pathname } = location;


  const orgImgSize = !isSidebarOpenOnSmallScreens ? 20 : 6;
  const branchNames = currentUser.userProfile.branches?.map( ( branch ) => branch.name );
  const navigate = useNavigate();

  const sidebarItems = router.map( ( group, index ) => {
      const menuItems = group.routes.map( ( route ) => {

          if ( route.subRoutes.length < 1 ) {
              return (
                  <Sidebar.Item
                      key={index + route.route}
                      onClick={() => {navigate( route.route );}}
                      icon={route.icon}
                      className={
                      activeMenu( pathname, route.route ) ? 'bg-gray-100 dark:bg-gray-700' : 'cursor-pointer'
                      }
                  >
                      {t( route.key )}
                  </Sidebar.Item>
              );
          }

         return (
             <Sidebar.Collapse
                 open={route.subRoutes.filter( ( sr )=> activeMenu( pathname,  sr.route )  ).length > 0 }
                 key={index + route.route}
                 icon={route.icon}
                 label={t( route.key )}
                 className="cursor-pointer"
             >
                 {
                     route.subRoutes.map( ( subRoute ) => {
                         return (
                             <Sidebar.Item
                                 key={index + subRoute.route}
                                 onClick={() => {navigate( subRoute.route );}}
                                 className={ activeMenu( pathname, subRoute.route )  ? 'cursor-pointer bg-gray-100 dark:bg-gray-700 text-sm ' : 'text-sm  cursor-pointer'
                                 }
                             >
                                 {t( subRoute.key )}
                             </Sidebar.Item>
                         );
                     } )
                 }
             </Sidebar.Collapse>
         );
      } );

      if( !currentUserCan( currentUser.userProfile.role?.permissions, group.permission ) ) {
        return '';
      }

      return (
          <Sidebar.ItemGroup key={index}>
              {menuItems}
          </Sidebar.ItemGroup>
      );

  } );

  return (
    <div
      className={classNames( 'lg:!block', {
        hidden: !isSidebarOpenOnSmallScreens,
      } )}
    >
      <Sidebar
          className={classNames( 'bg-white pt-67px dark:bg-gray-800', {
              hidden: isSidebarOpenOnSmallScreens
          } )}
          // collapsed={isSidebarOpenOnSmallScreens && !isSmallScreen()}
      >
        <div className="flex h-full flex-col justify-between py-2">
          <div>
            <Sidebar.Items>

            {
              currentUser.userProfile.organisation ?
                  <Sidebar.ItemGroup>
                  <OrganisationBadge
                      organisation={currentUser.userProfile.organisation}
                      avatarSize={orgImgSize}
                      branchName={branchNames?.join( ', ' )}
                  />
                  </Sidebar.ItemGroup>
                  : ''
            }

              {sidebarItems}

            </Sidebar.Items>
          </div>
          <BottomMenu />
        </div>
      </Sidebar>
    </div>
  );
};


export default ExampleSidebar;
