import { Breadcrumb } from 'flowbite-react';
import { HiHome } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import OrganisationForm from '@src/components/organisation/organisation-form';
import ViewGuard from '@src/components/view-guard/view-guard';
import { Permissions } from '@src/enums/Permissions';
import AppLayout from '@src/layouts/app-layout';
import TranslationNamespace from '@src/enums/translation-namespace';
import { useParams } from 'react-router';
import { useAuth } from '@src/context/auth-context';
import currentUserCan from '@src/helpers/current-user-can';
import { useGetOrganisationByIdQuery } from '@src/services/organisations-service';
import LoadingScreen from '@src/components/loading-screen/loading-screen';
import ServerErrorPage from '@src/app/error/error';
import { useNavigate } from 'react-router-dom';

const EditOrganisationComponent = () => {
    const { t } = useTranslation( [ TranslationNamespace.ORGANISATIONS, TranslationNamespace.COMMON, TranslationNamespace.VALIDATION_ERRORS ] );
    const { orgId } = useParams();
    const { data, isLoading, error } = useGetOrganisationByIdQuery( Number( orgId ) );
    const navigate = useNavigate();
    const { currentUser } = useAuth();

    if( isLoading ) {
        return <LoadingScreen />;
    }

    if ( error ) {
        return <ServerErrorPage />;
    }

    return(
        <ViewGuard
            permission={Permissions.CAN_DELETE_ORGANISATIONS}
            additionalCheck={() => {
                return currentUserCan( currentUser.userProfile.role?.permissions, Permissions.CAN_EDIT_CURRENT_ORGANISATION ) && currentUser.userProfile.organisation.id === Number( orgId );
            }}>
            <AppLayout title="Edit Organisation">
                <div className="grid grid-cols-1 gap-y-6 px-4 pt-6 dark:bg-gray-900 xl:grid-cols-3 xl:gap-4">
                    <div className="col-span-full">
                        <Breadcrumb className="mb-4">
                            <Breadcrumb.Item className="cursor-pointer" onClick={()=> navigate( '/' )}>
                                <div className="flex items-center gap-x-3">
                                    <HiHome className="text-xl" />
                                    <span className="dark:text-white">{t( 'common:home' )}</span>
                                </div>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item className="cursor-pointer" onClick={()=> navigate( '/organisation/list' )}>
                                <div className="flex items-center gap-x-3">
                                    <span className="dark:text-white">{t( 'common:organisations' )}</span>
                                </div>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item className="cursor-pointer" onClick={()=> navigate( `/organisation/${orgId}` )}>
                                <div className="flex items-center gap-x-3">
                                    <span className="dark:text-white">{data?.name}</span>
                                </div>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                {t( 'common:edit' )}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                        <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
                            { t( 'common:add_organisations' ) }
                        </h1>
                    </div>

                    <div className="col-span-full">
                        <OrganisationForm organisation={data} />
                    </div>
                </div>
            </AppLayout>
        </ViewGuard>
    );
};

export default EditOrganisationComponent;