import { Button } from 'flowbite-react';
import { HiChevronLeft } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import TranslationNamespace from '@src/enums/translation-namespace';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const NotFoundComponent =  () => {
    const navigate = useNavigate();
    const { t } = useTranslation( [ TranslationNamespace.NOT_FOUND, TranslationNamespace.COMMON ] );

    return (
        <HelmetProvider>
            <Helmet>
                <title>{t( 'common:page_not_found' )} | Advacare</title>
            </Helmet>
            <div className="flex min-h-screen flex-col items-center justify-center py-16">
                <img alt="" src="/images/illustrations/404.svg" className="lg:max-w-md" />
                <h1 className="mb-6 text-2xl font-bold dark:text-white md:text-5xl">
                    {t( 'common:page_not_found' )}
                </h1>
                <p className="mb-6 w-4/5 max-w-xl text-center text-lg text-gray-500 dark:text-gray-300">
                    {t( 'paragraph' )}
                </p>
                <Button onClick={()=> {navigate( '/' );}}>
                    <div className="mr-1 flex items-center gap-x-2">
                        <HiChevronLeft className="text-xl" />  {t( 'btn_text' )}
                    </div>
                </Button>
            </div>
        </HelmetProvider>
    );
};

export default NotFoundComponent;