import type { ReactNode } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import AdvaNavbar from '@src/components/navbar';
import Sidebar from '@src/components/sidebar';
import MainContent from '@src/components/main-content/main-content';

interface NavbarSidebarLayoutProps {
  isFooter?: boolean;
  children: ReactNode;
  title?: string
}
 
// @ts-ignore
const AppLayout = ( { children, isFooter = true, title }: NavbarSidebarLayoutProps ) => {

    const pageTitle = title ? `${title} | Advacare` : 'Advacare';

    return (
      <HelmetProvider>
          <Helmet>
            <title>{pageTitle}</title>
          </Helmet>
        <AdvaNavbar />
        <div className="mt-16 flex items-start">
          <Sidebar />
          <MainContent isFooter={isFooter}>{children}</MainContent>
        </div>
      </HelmetProvider>
    );
  };

export default AppLayout;
