import Joi from 'joi';

export const ForgotPasswordSchema = Joi.object( {
    email: Joi.string()
        .email( { tlds: { allow: false } } )
        .required()
        .messages( {
            'string.empty': 'required_field',
            'any.required': 'required_field',
            'string.email': 'valid_email'
        } )
} );

export const ChangePasswordSchema = Joi.object( {
    newPassword: Joi.string()
        .required()
        .pattern( new RegExp( '^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()\\\\-__+.]).{8,}$' ) )
        .messages( {
            'string.empty': 'required_field',
            'any.required': 'required_field',
            'string.pattern.base': 'password_pattern_error'
        } ),

    confirmNewPassword: Joi.string().valid( Joi.ref( 'newPassword' ) )
        .messages( {
            'string.empty': 'required_field',
            'any.required': 'required_field',
            'any.only': 'password_dont_match'
        } )
} );

export const UserChangePasswordSchema = Joi.object( {
    password: Joi.string()
        .required()
        .pattern( new RegExp( '^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()\\\\-__+.]).{8,}$' ) )
        .messages( {
            'string.empty': 'required_field',
            'any.required': 'required_field',
            'string.pattern.base': 'password_pattern_error'
        } ),

    repeatPassword: Joi.string().valid( Joi.ref( 'password' ) )
        .messages( {
            'string.empty': 'required_field',
            'any.required': 'required_field',
            'any.only': 'password_dont_match'
        } ),

    oldPassword: Joi.string()
        .required()
        .messages( {
            'string.empty': 'required_field',
            'any.required': 'required_field',
        } )
} );