import { Breadcrumb } from 'flowbite-react';
import { HiHome } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import OrganisationForm from '@src/components/organisation/organisation-form';
import TranslationNamespace from '@src/enums/translation-namespace';
import ViewGuard from '@src/components/view-guard/view-guard';
import { Permissions } from '@src/enums/Permissions';
import AppLayout from '@src/layouts/app-layout';
import { useNavigate } from 'react-router-dom';

const AddOrganisationComponent = () => {
    const { t } = useTranslation( [ TranslationNamespace.ORGANISATIONS, TranslationNamespace.COMMON, TranslationNamespace.VALIDATION_ERRORS ] );
    const navigate = useNavigate();
    return(
    <ViewGuard permission={Permissions.CAN_DELETE_ORGANISATIONS}>
       <AppLayout title={t( 'addOrganisationTitle' )}>
           <div className="grid grid-cols-1 gap-y-6 px-4 pt-6 dark:bg-gray-900 xl:grid-cols-3 xl:gap-4">
               <div className="col-span-full">
                   <Breadcrumb className="mb-4">
                       <Breadcrumb.Item
                           onClick={() => navigate( '/' )}
                           className="cursor-pointer"
                       >
                           <div className="flex items-center gap-x-3">
                               <HiHome className="text-xl" />
                               <span className="dark:text-white">{t( 'common:home' )}</span>
                           </div>
                       </Breadcrumb.Item>
                       <Breadcrumb.Item
                           onClick={() => navigate( '/organisation/list' )}
                           className="cursor-pointer"
                       >
                           <div className="flex items-center gap-x-3">
                               <span className="dark:text-white">{t( 'common:organisations' )}</span>
                           </div>
                       </Breadcrumb.Item>
                       <Breadcrumb.Item>
                           {t( 'common:add_organisations' )}
                       </Breadcrumb.Item>
                   </Breadcrumb>
                   <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
                       { t( 'common:add_organisations' ) }
                   </h1>
               </div>

               <div className="col-span-full">
                   <OrganisationForm />
               </div>
           </div>
       </AppLayout>
    </ViewGuard>
    );
};

export default AddOrganisationComponent;