import { OrganisationIntroProps } from '@src/types/organisation/i-organisation';
import { useTranslation } from 'react-i18next';
import * as DOMPurify from 'dompurify';

const GeneralInformation =  ( { organisation }: OrganisationIntroProps ) => {
    const { t } = useTranslation();
    const sanitizer = DOMPurify.sanitize;
    const desc =   organisation.description && organisation.description.length > 0 ? organisation.description : 'N/A';
    return (
        <div className="mb-4 rounded-lg bg-white p-4 shadow dark:bg-gray-800 sm:p-6 xl:p-8">
            <h3 className="mb-4 text-xl font-bold dark:text-white">
                {t( 'common:description' )}
            </h3>
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-2">
                    <dd className="mt-1 max-w-prose space-y-3 text-sm text-gray-500 dark:text-gray-400">
                       <div dangerouslySetInnerHTML= {{ __html: sanitizer( desc.replace( /\n/g, '<br />' ) ) }} />
                    </dd>
                </div>
            </dl>
        </div>
    );
};

export default GeneralInformation;