import { Alert, Button, Card } from 'flowbite-react';
import LanguageDropdown from '@src/components/sidebar/language-dropdown';
import AdvaForm from '@src/components/form/adva-form';
import AdvaTextInput from '@src/components/form/inputs/adva-text-input';
import { useEffect, useState } from 'react';
import { FormErrorState, LoginProps } from '@src/types/form/adva-form-props';
import LoginSchema from '@src/schemas/access/login-schema';
import SetFormErrorInitialState from '@src/helpers/set-form-error-initial-state';
import validateForm from '@src/helpers/validate-form';
import handleInputChange from '@src/helpers/handle-input-change';
import { useAuth } from '@src/context/auth-context';
import { HiInformationCircle } from 'react-icons/hi';
import ComponentLoading from '@src/components/loading-screen/component-loading';
import TranslationNamespace from '@src/enums/translation-namespace';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LogInComponent = () => {
    const [ formData, setFormData ] = useState<LoginProps>( { email: '', password : '' } );
    const [ errors, setErrors ] = useState<FormErrorState>( SetFormErrorInitialState( formData ) );
    const { login, authError, setAuthError } = useAuth();
    const [ sending, setSending ] = useState( false );
    const navigate = useNavigate();
    const { t } = useTranslation( [ TranslationNamespace.LOGIN, TranslationNamespace.COMMON, TranslationNamespace.VALIDATION_ERRORS ] );

    const handleChange = ( event: any ) => {
       handleInputChange(
           event,
           formData,
           setFormData,
           errors,
           setErrors
       );
    };

    useEffect( () => {
        if( authError ) {
            setSending( false );
        }
    }, [ authError ] );

    const handleSubmit =  async ( event: any ) => {
        event.preventDefault();
        setSending( true );
        setErrors( SetFormErrorInitialState( formData ) );

        const isValid = validateForm<LoginProps>( LoginSchema, formData, setErrors );

        if ( !isValid ) {
            setSending( false );
            return;
        }

        login!( formData.email, formData.password );
    };

    return (
        <HelmetProvider>
            <Helmet>
                <title>{t( 'pageTitle' )} | Advacare</title>
            </Helmet>
            <div className="flex flex-col items-center justify-center px-6 lg:h-screen lg:gap-y-12">
                <a href="/" className="my-6 flex items-center gap-x-1 lg:my-0">
                    <img
                        alt="Advacare"
                        src="/images/advacare2.svg"
                        className="mr-3 h-10"
                    />
                </a>
                <Card
                    horizontal
                    imgSrc="/images/authentication/login.jpg"
                    imgAlt=""
                    className="relative w-full md:max-w-[1024px] md:[&>*]:w-full md:[&>*]:p-16 [&>img]:hidden md:[&>img]:w-96 md:[&>img]:p-0 lg:[&>img]:block"
                >
                    <h1 className="mb-3 text-2xl font-bold dark:text-white md:text-3xl">
                        {t( 'heading' )}
                    </h1>

                    <AdvaForm onSubmit={( event ) => handleSubmit( event )}>
                        <AdvaTextInput
                            className="mb-6 flex flex-col gap-y-3"
                            value={formData.email}
                            id="email"
                            name="email"
                            placeholder="name@company.com"
                            type="text"
                            validation={errors['email']}
                            label={t( 'common:email' )}
                            onChange={( e: any ) => handleChange( e )}
                        />
                        <AdvaTextInput
                            className="mb-6 flex flex-col gap-y-3"
                            value={formData.password}
                            id="password"
                            name="password"
                            placeholder="••••••••"
                            type="password"
                            validation={errors['password']}
                            label={t( 'common:password' )}
                            onChange={( e: any ) => handleChange( e )}
                        />

                        <div className="text-right mb-3">
                            <a
                                onClick={()=> {navigate( '/access/forgot-password' );}}
                                className="cursor-pointer text-right text-sm  text-primary-600 dark:text-primary-300"
                            >
                                {t( 'forgot_password' )}
                            </a>
                        </div>

                        <div className="mb-6 ">
                            <Button disabled={sending} type="submit" className="w-full bg-primary-400 lg:w-auto">
                                {t( 'common:sign_in' )}
                            </Button>
                        </div>



                        {authError ?

                            <Alert
                                color="failure"
                                icon={HiInformationCircle}
                                onDismiss={() => {setAuthError!( '' );}}
                            >
                                {authError}
                            </Alert> : ''}
                    </AdvaForm>
                    {sending ?
                        <ComponentLoading /> :
                        '' }
                </Card>
                <div className="flex justify-center items-center">
                    <p className="mr-3">{t( 'common:language' )} </p>
                    <LanguageDropdown />
                </div>
            </div>
        </HelmetProvider>
    );
};

export default LogInComponent;