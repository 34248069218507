import { Avatar, Dropdown } from 'flowbite-react';
import { useAuth } from '@src/context/auth-context';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import currentUserCan from '@src/helpers/current-user-can';
import { Permissions } from '@src/enums/Permissions';

const UserDropdown = () => {

    const { logout, currentUser } = useAuth();
    const { t } = useTranslation();
    const { email, firstName, lastName, profile, organisation, role } = currentUser.userProfile;

    const profileImg = profile && profile.profileImage ? profile.profileImage : '/images/user-placeholder.png';
    const navigate = useNavigate();

    return (
        <Dropdown
            arrowIcon={false}
            inline
            label={
                <span>
          <span className="sr-only">{t( 'user_menu' )}</span>
          <Avatar
              alt=""
              img={profileImg}
              rounded
              size="sm"
          />
        </span>
            }
        >
            <Dropdown.Header>
                <span className="block text-sm">{firstName} {lastName}</span>
                <span className="block truncate text-sm font-medium">
          {email}
        </span>
            </Dropdown.Header>
            <Dropdown.Item onClick={()=>{navigate( '/' );}}>{t( 'common:dashboard' )}</Dropdown.Item>
            {
                currentUserCan( role?.permissions, Permissions.CAN_EDIT_CURRENT_ORGANISATION ) ?
                    <Dropdown.Item onClick={()=>{navigate( `/organisation/edit/${organisation.id}` );}}>{t( 'organisations:my_organisation' )}</Dropdown.Item> :
                    ''
            }
            <Dropdown.Item onClick={()=> {navigate( '/profile-settings' );}} >{t( 'common:profile' )}</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={logout}>{t( 'common:sign_out' )}</Dropdown.Item>
        </Dropdown>
    );
};

export default UserDropdown;