import { Card } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@src/context/auth-context';
import { HiLogout } from 'react-icons/hi';

const SignOutAll = () => {
    const { t } = useTranslation();
    const { logoutAll } = useAuth();
    return (
        <Card>
            <a onClick={logoutAll} className="dark:text-white flex items-center" href="#">
               <HiLogout /> <span className="ml-2">{t( 'common:sign_out_all' )}</span>
            </a>
        </Card>
    );
};

export default SignOutAll;