import { Permissions } from '@src/enums/Permissions';
import { HiBriefcase, HiChartPie } from 'react-icons/hi';

export const router = [
    {
        permission: Permissions.CAN_VIEW_EMPLOYEES_IN_BRANCH,
        routes: [
            {
                key: 'common:dashboard',
                route: '/',
                icon: HiChartPie,
                subRoutes: []
            }
        ]
    },
    {
        permission: Permissions.CAN_DELETE_ORGANISATIONS,
        routes: [
            {
                key: 'common:organisations',
                route: '#',
                icon: HiBriefcase,
                subRoutes: [
                    {
                        key: 'common:organisations_list',
                        route: '/organisation/list',
                    },
                    {
                        key: 'common:add_organisations',
                        route: '/organisation/add',
                    }
                ]
            }
        ]
    }
];